.container {
	$this: &;
	margin: 0 auto;
	width: 100%;

	&__wrapper {
		display: flex;
		max-width: $screen-xxl;
		margin: auto;
		padding: 0 15px;

		@include media($screen-xxl) {
			padding: 0;
		}

		&--underline {
			width: 100%;
			position: relative;
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		}

		&--text {
			max-width: 800px;
			justify-content: center;
		}
	}

	&__side {
		flex-shrink: 0;
		padding-left: 20px;

		@include media(md) {
			width: 200px;
			padding-left: 40px;
		}

		@include media(lg) {
			width: 300px;
		}

		@include media(xl) {
			padding-left: 70px;
		}
	}

	&__content {
		flex-grow: 1;
		padding-right: 20px;

		@include media(md) {
			padding-right: 40px;
		}

		@include media(xl) {
			padding-right: 70px;
		}
	}

	&--default {
		padding-left: 20px;
		padding-right: 20px;

		@include media(md) {
			padding-left: 40px;
			padding-right: 40px;
		}

		@include media(xl) {
			padding-left: 70px;
			padding-right: 70px;
		}
	}
}
