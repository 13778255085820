.socials-widget {
	width: 72px;
	height: 72px;
	background-color: #008AFF;
	border-radius: 50%;
	position: fixed;
	bottom: 3%;
	right: 3%;

	&__circle {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: white;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;

		svg {
			width: 50px;
			height: 50px;
		}

		div	{
			cursor: pointer;
			animation: slide 10s infinite linear;
			animation-direction: alternate;
		}
	}

	&__block {
		display: none;

		&::after {
			content: '';
			width: 15px;
			height: 15px;
			background: white;
			transform: rotate(45deg);
			position: absolute;
			bottom: -8px;
			right: 30px;
		}

		&.is-open {
			display: flex;
			flex-direction: column;
			position: absolute;
			bottom: 120%;
			padding: 20px;
			background: white;
			border-radius: 25px;
			width: 200px;
			align-items: baseline;
			right: 0;
			box-shadow: 5px 5px 5px grey;

			a {
				color: black;
				display: flex;
				align-items: center;

				svg	{
					width: 50px;
				}
			}
		}
	}
}

@keyframes slide {
	0% {
		margin-left: 0;
	}

	10% {
		margin-left: -50px;
	}

	20% {
		margin-left: -50px;
	}

	30% {
		margin-left: -100px;
	}

	40% {
		margin-left: -100px;
	}

	50% {
		margin-left: -150px;
	}

	60% {
		margin-left: -150px;
	}

	70% {
		margin-left: -200px;
	}

	80% {
		margin-left: -200px;
	}

	90% {
		margin-left: -250px;
	}

	100% {
		margin-left: -250px;
	}
}
