@import 'settings/all-settings';

////
/// @group blocks/
////

/// Description
.wysiwyg {
	color: $color-black;
	font-weight: $font-weight-normal;
	font-size: rem(17);
	line-height: 1.5em;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: $font-weight-bold;
		margin: 0.5em 0;
	}

	@include title();

	ul,
	ol {
		margin: 1.5em 0;
	}

	ul {
		padding-left: 20px;
		list-style-type: disc;
	}

	ol {
		padding-left: 40px;
		list-style-type: decimal;
	}

	dd {
		margin-left: 2em;
	}

	dt {
		font-weight: bold;
	}

	dd + dt {
		margin-top: 0.5em;
	}

	b,
	strong {
		font-weight: $font-weight-bolder;
	}

	u,
	ins {
		text-decoration: underline;
	}

	i:not(.fa),
	cite,
	em,
	var,
	address,
	dfn,
	caption {
		font-style: italic;
	}

	p,
	dl,
	pre,
	address,
	hr {
		margin: 1.5em 0;
	}

	hr {
		clear: both;
		border-top: 1px solid $color-gray-5;
	}

	:first-child {
		margin-top: 0;
	}

	:last-child {
		margin-bottom: 0;
	}

	p {
		&:empty {
			display: none;
		}
	}

	a:not(.button) {
		&[href] {
			color: $color-main;
			text-decoration: underline;
			// white-space: nowrap;

			&:hover {
				text-decoration: none;
			}
		}
	}

	abbr[title], // sass-lint:disable-line force-attribute-nesting
	dfn[title] { // sass-lint:disable-line force-attribute-nesting
		text-decoration: none;
		border-bottom: 1px dotted;
		cursor: help;
	}

	blockquote {
		padding-left: rem(20);
		border-left: 5px solid $color-main;
	}

	:not(pre) > code,
	kbd,
	samp {
		font-family: $font-family-code;
		padding: .1em 6px;
		white-space: normal;
		hyphens: none;
		tab-size: 4;
		word-spacing: normal;
		word-break: normal;
		word-wrap: normal;
		text-align: left;
		background-color: $color-gray-1;
	}

	table  {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid $color-gray-2;
	}

	caption {
		padding: .5em;
		caption-side: top;
		border-left: 1px solid $color-gray-2;
		border-right: 1px solid $color-gray-2;
		border-top: 1px solid $color-gray-2;
	}

	tbody,
	tfoot,
	thead,
	tr,
	th,
	td {
		border: inherit;
	}

	th,
	td {
		padding: .5em;
		text-align: left;
		vertical-align: top;
	}

	th {
		font-weight: bold;
		text-align: center;
		color: $color-white;
		background-color: $color-main;
	}

	.table-null {
		background-color: transparent;

		caption {
			border-left: none;
			border-right: none;
			border-top: none;
		}

		&,
		th,
		td {
			border-color: transparent;
		}

		th {
			background: transparent;
		}
	}

	.table-zebra {
		tbody tr:nth-child(odd) { // sass-lint:disable-line force-element-nesting
			background-color: $color-gray-2;
		}
	}

	.table-2-columns {
		width: 100%;

		td {
			width: 100%;
			float: left;
			box-sizing: border-box;

			@include media($screen-md) {
				width: 50%;
				float: none;
			}
		}
	}

	.table-3-columns {
		width: 100%;

		td {
			width: 100%;
			float: left;
			box-sizing: border-box;
			padding-left: 0;
			padding-right: 0;

			@include media($screen-sm) {
				width: 33%;
				float: none;
				padding: rem(20);

				& + td {
					border-left: 1px solid $color-gray-2;
				}
			}
		}
	}

	.table-4-columns {
		width: 100%;

		td {
			width: 100%;
			float: left;
			box-sizing: border-box;
			vertical-align: top;

			@include media($screen-xxs) {
				width: 50%;
				float: left;
			}

			@include media($screen-md) {
				width: 25%;
				float: none;
			}
		}
	}

	iframe {
		border-width: 0;
	}

	img {
		display: inline;

		&.is-full {
			display: block;
			float: none !important; // sass-lint:disable-line no-important
			margin-left: auto !important; // sass-lint:disable-line no-important
			margin-right: auto !important; // sass-lint:disable-line no-important
		}
	}

	audio {
		width: 100%;
	}

	audio[controls] { // sass-lint:disable-line force-attribute-nesting
		display: block;
	}

	figure {
		margin-left: 0;
		margin-right: 0;
		padding: 1rem;
		text-align: center;
		border: 1px solid $color-gray-2;
		box-sizing: border-box;

		p {
			margin: 0;
		}
	}

	figcaption {
		padding-top: 0.5rem;
		font-style: italic;
	}

	&--article {
		max-width: 780px;
	}
}
