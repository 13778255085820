@mixin set-scroll-bar ($prefix: '', $width: 17px, $color: $color-gray-5) {
	#{$prefix}::-webkit-scrollbar-track {
		background-color: rgba($color, 0.2);
	}

	#{$prefix}::-webkit-scrollbar-thumb {
		background-color: rgba($color, 0.8);

		&:hover {
			background-color: rgba($color, 1);
		}
	}

	#{$prefix}::-webkit-scrollbar {
		width: $width;
	}
}

::-webkit-scrollbar-button {
	display: none;
}

::-webkit-resizer {
	display: none;
}

::-webkit-scrollbar-thumb {
	border-radius: 0;
	transition: background-color 0.3s;
}

@include set-scroll-bar('', 8px, $color-black);
