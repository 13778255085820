@mixin title ($prefix: "") {
	#{$prefix}h1 {
		font-size: rem(36);
		line-height: 1.1em;

		@include media(ms) {
			font-size: rem(42);
		}

		@include media(lg) {
			font-size: rem(50);
		}
	}

	#{$prefix}h2 {
		font-size: rem(30);
		line-height: 1.2em;

		@include media(ms) {
			font-size: rem(36);
		}

		@include media(lg) {
			font-size: rem(42);
		}
	}

	#{$prefix}h3 {
		font-size: rem(24);

		@include media(ms) {
			font-size: rem(32);
		}
	}

	#{$prefix}h4 {
		font-size: rem(24);
	}

	#{$prefix}h5 {
		font-size: rem(21);
	}

	#{$prefix}h6 {
		font-size: rem(18);
	}
}
