@mixin preloader ($classname: 'is-pending') {
	$ttf: cubic-bezier(.42, .61, .58, .41);

	box-sizing: border-box;
	position: relative;

	& > * { // sass-lint:disable-line no-universal-selectors
		transition-property: opacity, filter;
		transition-duration: $time-fast;
		transition-timing-function: ease;
		will-change: opacity, filter;
	}

	&.#{$classname} {
		&::before,
		&::after {
			box-sizing: inherit;
			content: '';
			position: absolute;
			z-index: 4;
			display: block;
			border: 4px solid $color-main;
			border-radius: 50%;
		}

		&::before {
			left: calc(50% - 30px);
			top: calc(50% - 30px);
			width: 60px;
			height: 60px;
			border-left-color: rgba($color-main, .001);
			border-bottom-width: 0;
			animation: rotating 1s $ttf infinite;
		}

		&::after {
			left: calc(50% - 20px);
			top: calc(50% - 20px);
			width: 40px;
			height: 40px;
			border-right-width: 0;
			border-top-color: rgba($color-main, .001);
			animation: rotating 1s $ttf infinite reverse;
		}

		& > * { // sass-lint:disable-line no-universal-selectors
			opacity: .3;
			pointer-events: none;
			filter: grayscale(1);
		}
	}

	@keyframes rotating {
		0% {
			transform: rotate(0deg) translateZ(0);
		}

		100% {
			transform: rotate(360deg) translateZ(0);
		}
	}
}
