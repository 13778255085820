.services-page {
	&__block {
		background-position: center!important;
		background-size: cover!important;;
		background-repeat: no-repeat!important;;
		height: 66vh;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}

	&__center {
		line-height: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 66vh;
		padding-top: 100px;
	}

	&__title {
		font-family: FuturaPT-Demi, Arial, sans-serif;
		line-height: 35px;
		color: #FFFFFF;
		font-size: 35px;

		@include media($screen-sm) {
			line-height: 50px;
			font-size: 50px;
		}

		@include media($screen-lg) {
			line-height: 80px;
			font-size: 80px;
		}

		&--sup {
			font-family: Play, sans-serif;
			color: #82C5FF;
			text-transform: uppercase;
			font-size: 16px;
		}
	}

	&__content {
		padding-top: 60vh;

		//@include media($screen-sm) {
		//	padding-top: 60vh;
		//}

		@include media($screen-def) {
			padding-top: 50vh;
		}

		@include media($screen-lg) {
			padding-top: 40vh;
		}

		&--sup-title {
			font-family: Play;
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.3em;
			text-transform: uppercase;
			color: #82C5FF;
		}

		&--title {
			font-family: FuturaPT-Demi;
			font-size: 40px;
			line-height: 40px;
			color: #000;
		}

		&--text {
			font-family: Play;
			font-size: 18px;
			line-height: 30px;
		}

		&--text-custom {
			font-family: Play;
			border: 1px solid rgba(0, 0, 0, 0.2);
			border-left: 5px solid black;
			padding-top: 30px;
			padding-bottom: 30px;
		}

		&--phone {
			font-family: Play;
			font-weight: bold;
			font-size: 20px;
			color: #000000;
			margin: 10px 5px;

			@include media($screen-sm) {
				margin: 0 10px;

				&:nth-child(2) {
					&::before {
						left: 33%;
					}

					&::after {
						right: 33%;
					}

					&::before,
					&::after {
						content: '';
						position: absolute;
						top: 10px;
						display: inline-block;
						width: 5px;
						height: 5px;
						border-radius: 50%;
						background-color: #82C5FF;
					}
				}
			}
		}

		.info-block {
			font-family: Play;
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.3em;
			text-transform: uppercase;
			color: #82C5FF;
			display: flex;
			justify-content: center;
			text-align: center;
			align-items: center;

			span {
				width: 100%;
			}

			&::before,
			&::after {
				content: '';
				display: flex;
				width: 100%;
				height: 2px;
				background: #82C5FF;
			}
		}
	}

	&__image-block {
		background: linear-gradient(90deg, rgba(255,255,255,0.5704656862745099) 10%, rgba(247,248,251,1) 10%, rgba(247,248,251,1) 90%, rgba(255,255,255,1) 90%);
		height: auto;
		width: 100%;
		margin-bottom: 60px;

		@include media($screen-def) {
			margin-top: 75px;
			height: 484px;
		}
	}

	&__image {
		display: block;
		position: relative;
		max-width: 50%;

		@include media($screen-xl) {
			max-width: 650px;
		}

		&--first {
			top: -25px;

			@include media($screen-ms) {
				top: -75px;
			}
		}

		&--second {
			top: 30px;

			@include media($screen-ms) {
				top: 60px;
			}
		}
	}

	.button {
		border-color: white;
	}
}

