.contact-info {
	padding-top: 50px;

	@include media(md) {
		padding-top: 70px;
	}

	@include media(xl) {
		padding-top: 70px;
	}

	&__text {
		color: $color-black;
		font-size: rem(18);
		margin-bottom: 25px;
		text-align: center;
		font-family: $font-family-accent;

		@include media(md) {
			text-align: left;
		}

		@include media(xl) {
			margin-bottom: 30px;
		}
	}

	&__link {
		color: $color-black;
		font-size: rem(20);
		transition: color 0.3s;
		text-decoration: none;
		font-family: $font-family-accent;

		@include media(xl) {
			font-size: rem(36);
		}

		&[href]:hover {
			color: $color-main;
		}
	}
}
