$anim-duration: 80s;
$anim-delay: 40s;

.move-text {
	$this: &;
	padding-top: 20px;
	overflow: hidden;

	@include media(md) {
		padding-top: 50px;
	}

	@include media(xl) {
		padding-top: 120px;
	}

	&__block {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		animation-duration: $anim-duration;
		animation-timing-function: linear;

		&--1 {
			animation-iteration-count: infinite;
		}

		&--2 {
			animation-iteration-count: infinite;
			animation-delay: $anim-delay;
		}

		&--3 {
			animation-iteration-count: 1;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		cursor: default;

		+ #{$this}__item {
			margin-top: rem(20);

			@include media(md) {
				margin-top: rem(30);
			}
		}

		&--reverse {
			direction: rtl;
		}
	}

	&__word {
		flex-shrink: 0;
		margin-right: rem(50);

		@include media(md) {
			margin-right: rem(100);
		}

		@include media(xl) {
			margin-right: rem(156);
		}
	}

	&__text {
		font-size: 30px;
		line-height: 1.2em;
		text-transform: uppercase;
		color: $color-white;
		-webkit-text-stroke: 1px $color-black;
		white-space: nowrap;
		transition: color 0.3s;

		@include media(xs) {
			font-size: 50px;
		}

		@include media(md) {
			font-size: 75px;
		}

		@include media(xl) {
			-webkit-text-stroke: 2px $color-black;
			font-size: 110px;
		}

		&--light {
			color: transparent;
			-webkit-text-stroke: 1px $color-white;

			@include media(xl) {
				-webkit-text-stroke: 2px $color-white;
			}
		}

		&:hover {
			color: $color-main;
			-webkit-text-stroke: 1px $color-main;

			@include media(xl) {
				-webkit-text-stroke: 2px $color-main;
			}
		}
	}

	&__icon {
		width: rem(20);
		height: rem(20);
		background-color: $color-main;
		border-radius: 50%;
		flex-shrink: 0;
		margin-right: rem(50);

		@include media(md) {
			margin-right: rem(100);
			width: rem(30);
			height: rem(30);
		}

		@include media(xl) {
			margin-right: rem(156);
			width: rem(49);
			height: rem(49);
		}
	}

	&__image {
		display: none;
	}

	&__canvas {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		pointer-events: none;
	}

	&.is-active {
		opacity: 1;

		#{$this}__block {
			&--1 {
				animation-iteration-count: infinite;
				animation-name: marquee;
			}

			&--2 {
				animation-iteration-count: infinite;
				animation-delay: $anim-delay;
				animation-name: marquee2;
			}

			&--3 {
				animation-iteration-count: 1;
				animation-name: marquee3;
			}
		}

		#{$this}__item {
			&--reverse {
				direction: rtl;

				#{$this}__block {
					&--1 {
						animation-name: marquee-rev;
					}

					&--2 {
						animation-name: marquee2-rev;
					}

					&--3 {
						animation-name: marquee3-rev;
					}
				}
			}
		}
	}
}

@keyframes marquee {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(-100%);
	}
}

@keyframes marquee2 {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(-200%);
	}
}

@keyframes marquee3 {
	from {
		transform: translateX(-200%);
	}

	to {
		transform: translateX(-400%);
	}
}

@keyframes marquee-rev {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translateX(100%);
	}
}

@keyframes marquee2-rev {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(200%);
	}
}

@keyframes marquee3-rev {
	from {
		transform: translateX(200%);
	}

	to {
		transform: translateX(400%);
	}
}
