////
/// @group blocks/noscript/
////

/// Уведомление
.global-msg {
	position: fixed;
	left: 0;
	right: 0;
	z-index: 9999;
	clear: both;
	font-size: 14px;
	line-height: 1.3em;
	backface-visibility: hidden;
	outline: none;
	padding: 0;
	margin: 0;
	border-width: 0;

	&--top {
		top: 0;
	}

	&--bottom {
		bottom: 0;
	}

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	&__input:checked + &__container {
		display: none;
	}

	&__container {
		padding: .75em 1.5em;
		background-color: $color-gray-6;
		color: $color-white;
		@include clearfix();
	}

	&__close {
		display: block;
		float: right;
		width: 1em;
		height: 1em;
		font-size: 3em;
		line-height: 1;
		margin-left: .5em;
		text-align: center;
		cursor: pointer;

		&:hover {
			background-color: rgba(#000, .075);
		}

		&:active {
			background-color: rgba(#000, .15);
		}
	}

	&__content {
		overflow: hidden;

		p {
			margin: 0.5em 0;
		}

		b {
			font-weight: bold;
		}
	}
}
