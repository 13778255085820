.layout {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;

	&__header {
		flex-grow: 0;
		flex-shrink: 0;
		z-index: 1;
	}

	&__content {
		flex-grow: 1;
		flex-shrink: 0;
	}

	&__footer {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__video {
		overflow: hidden;
		min-height: 100%;
	}
}
