.mobile-menu {
	$this: &;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 60px;
	left: 0;
	z-index: -1;

	&__container {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 100%;
		background-color: #fff;
		overflow-x: hidden;
		transition: transform 0.5s ease-out;
		transform: translateX(100%);

		@include media(ms) {
			width: 50%;
		}

		@include media(lg) {
			width: 30%;
		}
	}

	&__block {
		position: relative;
	}

	&__link {
		display: block;
		color: $color-black;
		font-family: $font-family-accent;
		line-height: 1.5em;
		transition: 0.3s;
		border-bottom: 1px solid #CACBCE;
		padding: 20px 50px;

		&::first-line {
			font-weight: bold;
		}

		&::before {
			content: '';
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #82C5FF;
			display: inline-block;
			margin-right: 20px;
		}

		&:hover,
		&.is-active {
			color: $color-white;
			background-color: #242A3A;
			cursor: pointer;
		}

		&--main {
			position: absolute;
			width: 30px;
			right: 0;
			display: flex;
			align-items: center;
			margin-top: -32px;

			&::before {
				content: url("data:image/svg+xml,%3Csvg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM4 10.5L2 10.5L2 13.5L4 13.5L4 10.5Z' fill='%2382C5FF'/%3E%3C/svg%3E%0A");
				position: absolute;
				height: 100%;
				display: flex;
				transform: rotate(180deg);
				align-items: center;
				cursor: pointer;
			}

			&.is-active {
				& + .mobile-menu__link--sub {
					transform: translateX(0);
				}
			}
		}

		&--sub {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 100%;
			background-color: #fff;
			overflow-x: hidden;
			transition: transform 0.5s ease-out;
			transform: translateX(100%);
			text-align: center;
			z-index: 2;

			.mobile-menu__link::before {
				display: none;
			}
		}

		&--title {
			background: #EBF0F5;
			position: relative;

			&::before {
				content: url("data:image/svg+xml,%3Csvg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM4 10.5L2 10.5L2 13.5L4 13.5L4 10.5Z' fill='%2382C5FF'/%3E%3C/svg%3E%0A");
				display: inline-block!important;
				margin-right: 20px;
				background: transparent;
				position: absolute;
				left: 30px;
				width: auto;
				height: auto;
			}
		}

		@include media(xxs) {
			padding: 40px 50px;
		}
	}

	&__bg {
		background-color: $color-black;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.5s ease-out;
	}

	&.is-open {
		z-index: 1000;
	}

	&.is-opening &__container {
		transform: translateX(0);
	}

	&.is-opening &__bg {
		opacity: 0.6;
	}
}
