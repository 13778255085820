.link {
	display: inline-block;
	color: $color-black;
	font-size: rem(17);
	transition: color 0.3s;
	position: relative;
	padding-bottom: 23px;

	&::before,
	&::after {
		content: '';
		background-color: $color-black;
		transition: color 0.3s;
		position: absolute;
		height: 1px;
	}

	&::before {
		width: 100%;
		bottom: 10px;
		left: 0;
	}

	&::after {
		width: 15px;
		bottom: 10px;
		right: 0;
		transform: rotate(-45deg);
		transform-origin: 100% 0;
	}

	&[href]:hover {
		color: $color-secondary;

		&::before,
		&::after {
			background-color: $color-secondary;
		}
	}
}
