.static-text {
	color: $color-black;
	font-family: $font-family-accent;
	font-size: 30px;
	line-height: 1.2em;
	text-transform: uppercase;
	text-align: center;

	@include media(md) {
		font-size: 50px;
		text-align: left;
	}

	@include media(xl) {
		font-size: 90px;
	}
}
