////
/// @group _blocks/
////

/// Description
.ratio {
	position: relative;

	&--1x1 {
		padding-top: 100%;
	}

	&--16x9 {
		padding-top: 56.25%;
	}

	> * { // sass-lint:disable-line no-universal-selectors
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
