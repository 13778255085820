.line {
	height: 1px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 100%;
		background-color: rgba($color-black, 0.15);
		transition: width 2s;
	}

	&.is-show {
		&::before {
			width: 100%;
		}
	}
}
