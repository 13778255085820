.mobile-phone {
	$this: &;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;

	&__container {
		font-family: Play;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		height: fit-content;
		width: 100%;
		max-width: 350px;
		background-color: #fff;
		overflow-x: hidden;
		transition: transform 0.5s ease-out;
		transform: scale(0);
		padding: 20px 40px;
		color: #242A3A;

		.is-opening & {
			transform: scale(1);

		}
	}

	&__block {
		position: relative;

		+ #{$this}__block {
			margin-top: 30px;
		}
	}

	&__title {
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 15px;
	}

	&__time {
		font-size: 12px;
		line-height: 16px;
		color: #B9BBBD;
		margin-bottom: 15px;
	}

	&__text {
		font-size: 18px;
		line-height: 30px;
		color: #242A3A;
		margin-bottom: 15px;
	}

	&__link {
		display: block;
		font-size: 20px;
		line-height: 32px;
		color: #000000;
	}

	&__bg {
		background-color: $color-black;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.5s ease-out;
	}

	&.is-open {
		z-index: 1000;
	}

	&.is-opening &__bg {
		opacity: 0.6;
	}

	&__close {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		fill: $color-black;
		transition: fill 0.3s ease-out;
		position: absolute;
		top: 0;
		right: 0;

		svg {
			width: 70%;
			height: 70%;
		}

		&:hover {
			fill: $color-main;
		}
	}
}

