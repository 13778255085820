.services {
	$this: &;

	&__title {
		font-family: $font-family-accent;
		font-size: 30px;
		line-height: 1em;
		text-transform: uppercase;
		padding-top: 50px;
		padding-bottom: 50px;
		text-align: center;

		@include media(xs) {
			font-size: 40px;
		}

		@include media(md) {
			font-size: 50px;
			padding-top: 70px;
			padding-bottom: 70px;
			text-align: left;
		}

		@include media(xl) {
			font-size: 50px;
			padding-top: 70px;
			padding-bottom: 70px;
		}
	}

	&__image {
		position: relative;
		max-width: 425px;
		width: 100%;

		@include media(xl, max) {
			text-align: center;
		}

		img {
			width: 100% !important; // sass-lint:disable-line no-important
			max-height: 544px;
			height: 100%;

			@media screen and (max-width: 1561px) {
				height: auto;
			}

			@include media(xl, max) {
				display: inline-block;
				width: 420px !important; // sass-lint:disable-line no-important
				max-height: 544px;
			}

			@include media(xs, max) {
				width: 100% !important; // sass-lint:disable-line no-important
			}
		}

		&--offset {
			@include media(xl) {
				margin-top: 125px;
			}
		}
	}

	&__content {
		display: flex;
		justify-content: center;
	}

	&__block {
		position: relative;
		padding-top: 20px;

		@include media(xs) {
			max-width: 420px;
		}

		@include media(xl) {
			padding-top: 110px;
		}

		&::before {
			content: '';
			width: rem(85);
			height: 1px;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba($color-black, 0.15);
		}

		@include media(xl, max) {
			text-align: center;

			&::before {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	&__name {
		color: $color-black;
		font-family: $font-family-accent;
		font-size: 20px;
		margin-bottom: 20px;

		@include media(md) {
			font-size: 30px;
			margin-bottom: 50px;
		}

		@include media(xl) {
			font-size: 50px;
		}
	}

	&__text {
		margin-bottom: 20px;

		@include media(md) {
			margin-bottom: 50px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;

		+ #{$this}__list {
			padding-top: 50px;

			@include media(xl) {
				padding-top: 130px;
			}
		}
	}

	&__item {
		width: 100%;

		@include media(xl) {
			width: 50%;
		}

		&--first {
			@include media(xl) {
				padding-right: rem(25);
				width: 230px;
			}

			@include media(mg) {
				width: 450px;
			}
		}

		&--second {
			@include media(xl) {
				width: calc((100% - 230px) / 2);
				padding-right: rem(25);
			}

			@include media(mg) {
				width: calc((100% - 450px) / 2);
			}
		}

		&--third {
			@include media(xl) {
				width: calc((100% - 230px) / 2);
				padding-left: rem(25);
			}

			@include media(mg) {
				width: calc((100% - 450px) / 2);
			}
		}

		&--text {
			@include media(xl, max) {
				order: 1;
				margin-bottom: 30px;
			}
		}

		&--image {
			@include media(xl, max) {
				width: 50% !important; // sass-lint:disable-line no-important
				order: 2;
				margin-bottom: 30px;
				padding-left: rem(15);
				padding-right: rem(15);
			}

			@include media(ms, max) {
				width: 100% !important; // sass-lint:disable-line no-important
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&__canvas {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		height: 100%;
		pointer-events: none;
		will-change: transform;
		transform: translateZ(0);
	}
}
