.similar-item {
	padding: 90px 0;
	text-align: center;
	// sass-lint:disable no-important
	background-position: center!important;
	background-size: cover!important;
	background-repeat: no-repeat!important;

	@include media($screen-ms) {
		padding: 130px 0;
	}

	&__sup-title {
		font-family: Play;
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		color: #82C5FF;
	}

	&__title {
		font-family: FuturaPT-Demi;
		font-size: 2rem;
		text-align: center;
		color: #FFFFFF;
		height: 5.5rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__button {
		color: white;
		border-color: white;

		&:hover {
			cursor: pointer;
		}
	}
}
