.form {
	$this: &;

	display: block;
	width: 100%;
	margin: 0 auto;
	padding: 0;
	background-color: #fff;

	form {
		@include preloader();

		display: block;
		margin: 0;
	}
}
