.timer-container {
	text-align: center;
	width: rem(275);
	max-width: 100%;
}

.timer-text {
	font-size: 12px;
	color: #bfbfbf;
	margin-bottom: 5px;
}

.timer {
	&.timeTo { // sass-lint:disable-line class-name-format
		&.timeTo-white { // sass-lint:disable-line class-name-format
			div {
				background: none;
				color: #fafafa;
				border: none !important; // sass-lint:disable-line no-important
			}
		}

		> span {
			display: none;
		}

		> figure {
			position: relative;
			text-align: center;
			padding-right: 11px;
			width: auto !important; // sass-lint:disable-line no-important
			max-width: initial !important; // sass-lint:disable-line no-important

			> div {
				display: inline-block;
				margin-right: 0 !important; // sass-lint:disable-line no-important
			}

			> figcaption {
				padding-right: 0 !important; // sass-lint:disable-line no-important
			}

			&::after {
				content: ':';
				position: absolute;
				top: -2px;
				right: 0;
				color: #fafafa;
			}

			&:nth-last-child(3) {
				&::after {
					display: none;
				}
			}

			&:last-child {
				display: none;
			}
		}
	}
}
