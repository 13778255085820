$button-height: 60px;
$button-svg-size: 25px;

.button {
	$this: &;

	border: 1px solid rgba(255, 255, 255, 0.2);
	color: $color-white;
	fill: $color-white;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: rem(16);
	line-height: 1.3em;
	position: relative;
	transition: 0.3s;
	height: rem($button-height);
	min-width: rem(255);
	max-width: 100%;
	padding: 0 rem(20);
	cursor: default;
	text-align: center;
	overflow: hidden;
	top: 0;

	&[href] {
		cursor: pointer;
	}

	span {
		display: inline-block;

		+ svg {
			margin-left: 10px;
		}
	}

	svg {
		width: rem($button-svg-size);
		height: rem($button-svg-size);

		+ span {
			margin-left: 10px;
		}
	}

	&:hover,
	&:focus {
		background-color: $color-main-darken;
	}

	&:active {
		top: 5px;
	}

	&--disabled,
	&[disabled],
	&.is-disabled {
		cursor: not-allowed;
		opacity: 0.75;
		filter: grayscale(1);

		&:active {
			top: 0;
		}
	}

	&--full {
		width: 100%;
		min-width: 0;
	}

	&--icon {
		min-width: rem($button-height);
		padding: 0;

		.close {
			display: none;
		}

		&.is-open {
			background-color: #006ed9;

			.close {
				display: block;
			}
			
			.open {
				display: none;
			}
		}
	}
}
