.logo {
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	border: 1px solid rgba(255, 255, 255, 0.2);
	height: 60px;

	img {
		max-height: 100%;
	}

	@include media($screen-def) {
		padding-left: 0;
		display: block;
		border: none;
		width: auto;
		height: auto;
		max-width: 100%;
	}
}
