////
/// @group settings/
////

// ----------------------------------------
// Borders
// ----------------------------------------

$border-radius-small: 3px;
$border-radius-default: 4px;

// ----------------------------------------
// Colors
// ----------------------------------------

$color-white: #ffffff;
$color-gray-1: #f5f5f6;
$color-gray-2: #e9ecef;
$color-gray-3: #dee2e6;
$color-gray-4: #cbcbcb;
$color-gray-5: #adb5bd;
$color-gray-6: #737373;
$color-gray-7: #495057;
$color-gray-8: #343a40;
$color-gray-9: #212529;
$color-black: #000000;

$color-success: #59ad49;
$color-info: #008aff;
$color-warning: #ffae00;
$color-danger: #db402c;

$color-main: #008aff;
$color-main-darken: #006ed9;

$color-secondary: #ffae00;
$color-secondary-darken: #e09700;

$colors-map: (
	white: $color-white,
	black: $color-black,
	main: $color-main,
	secondary: $color-secondary
);

// ----------------------------------------
// Screens
// ----------------------------------------

$screen-st: 320px;
$screen-xxs: 375px;
$screen-xs: 480px;
$screen-sm: 568px;
$screen-ms: 640px;
$screen-md: 768px;
$screen-def: 1024px;
$screen-lg: 1280px;
$screen-xl: 1366px;
$screen-xxl: 1440px;
$screen-mg: 1560px;
$screen-xmg: 1668px;
$screen-hd: 1920px;
$screen-2k: 2048px;
$screen-4k: 3840px;

$screen-orientation-auto: 'only screen and (orientation: auto)';
$screen-orientation-landscape: 'only screen and (orientation: landscape)';
$screen-orientation-portrait: 'only screen and (orientation: portrait)';

$screens-map: (
	xs: $screen-xs,   // 480
	sm: $screen-sm,   // 568
	ms: $screen-ms,   // 640
	md: $screen-md,   // 768
	def: $screen-def, // 1024
	lg: $screen-lg,   // 1280
	xl: $screen-xl,   // 1366
	mg: $screen-mg    // 1560
);

// ----------------------------------------
// Gutters
// ----------------------------------------

$gutter-none: 0;
$gutter-xxs: 2px;
$gutter-xs: rem(6);
$gutter-sm: rem(10);
$gutter-md: rem(16);
$gutter-def: rem(20);
$gutter-xlg: rem(26);
$gutter-lg: rem(30);
$gutter-xl: rem(40);
$gutter-mg: rem(50);
$gutter-xxl: rem(60);
$gutter-xmg: rem(120);

$gutters-map: (
	0: $gutter-none,
	6: $gutter-xs,
	10: $gutter-sm,
	16: $gutter-md,
	20: $gutter-def,
	26: $gutter-xlg,
	30: $gutter-lg,
	40: $gutter-xl,
	50: $gutter-mg,
	60: $gutter-xxl,
	120: $gutter-xmg
);

// ----------------------------------------
// Network colors
// ----------------------------------------

$network-color-blogger: #f58220;
$network-color-bobrdobr: #763c34;
$network-color-delicious: #003bd8;
$network-color-digg: #165495;
$network-color-evernote: #75bb1e;
$network-color-facebook: #44619d;
$network-color-facebook--hover: #5976b1;
$network-color-facebook--active: #36538f;
$network-color-formspring-me: #4693d7;
$network-color-google-plus: #db402c;
$network-color-google-map-bg: #e5e3df;
$network-color-instapaper: #6b6b6b;
$network-color-juick: #b98337;
$network-color-linked-in: #007ab5;
$network-color-liveinternet: #27819b;
$network-color-livejournal: #6899c4;
$network-color-mail-ru: #2f69a1;
$network-color-memori: #fd2668;
$network-color-myspace: #1d1d1d;
$network-color-odnoklassniki: #f58220;
$network-color-pinterest: #cb2027;
$network-color-readability: #9d0000;
$network-color-rss: #fb7629;
$network-color-springpad: #f7b42f;
$network-color-stumbleupon: #ed4713;
$network-color-surfingbird: #14aef8;
$network-color-telegram: #0088cc;
$network-color-tumblr: #2e4e67;
$network-color-twitter: #64a8f2;
$network-color-vk: #41658b;
$network-color-webdiscover: #8bc514;
$network-color-yahoo: #7b0099;
$network-color-youtube: #ff0000;
$network-color-instagram: #893dc2;
$network-color-reddit: #ff4500;
$network-gradient-instagram: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);

// ----------------------------------------
// Font family
// ----------------------------------------

$font-family-main: Arial, sans-serif;
$font-family-accent: FuturaPT-Demi, Arial, sans-serif;
$font-family-code: monospace;
$font-family-play: Play, sans-serif;

// ----------------------------------------
// Font weights
// ----------------------------------------

$font-weight-thin: 100;
$font-weight-lighter: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: 900;

// ----------------------------------------
// Times
// ----------------------------------------

$time-now: .1s;
$time-fast: .2s;
$time-normal: .3s;
$time-slow: .5s;
$time-turtle: .7s;
$time-snail: 1s;

// ----------------------------------------
// Animate
// ----------------------------------------

$animate-step: 0.3;
