@charset "UTF-8";
.wysiwyg {
  color: #000000;
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 1.5em; }
  .wysiwyg h1,
  .wysiwyg h2,
  .wysiwyg h3,
  .wysiwyg h4,
  .wysiwyg h5,
  .wysiwyg h6 {
    font-weight: 700;
    margin: 0.5em 0; }
  .wysiwyg h1 {
    font-size: 2.25rem;
    line-height: 1.1em; }
    @media only screen and (min-width: 641px) {
      .wysiwyg h1 {
        font-size: 2.625rem; } }
    @media only screen and (min-width: 1281px) {
      .wysiwyg h1 {
        font-size: 3.125rem; } }
  .wysiwyg h2 {
    font-size: 1.875rem;
    line-height: 1.2em; }
    @media only screen and (min-width: 641px) {
      .wysiwyg h2 {
        font-size: 2.25rem; } }
    @media only screen and (min-width: 1281px) {
      .wysiwyg h2 {
        font-size: 2.625rem; } }
  .wysiwyg h3 {
    font-size: 1.5rem; }
    @media only screen and (min-width: 641px) {
      .wysiwyg h3 {
        font-size: 2rem; } }
  .wysiwyg h4 {
    font-size: 1.5rem; }
  .wysiwyg h5 {
    font-size: 1.3125rem; }
  .wysiwyg h6 {
    font-size: 1.125rem; }
  .wysiwyg ul,
  .wysiwyg ol {
    margin: 1.5em 0; }
  .wysiwyg ul {
    padding-left: 20px;
    list-style-type: disc; }
  .wysiwyg ol {
    padding-left: 40px;
    list-style-type: decimal; }
  .wysiwyg dd {
    margin-left: 2em; }
  .wysiwyg dt {
    font-weight: bold; }
  .wysiwyg dd + dt {
    margin-top: 0.5em; }
  .wysiwyg b,
  .wysiwyg strong {
    font-weight: 900; }
  .wysiwyg u,
  .wysiwyg ins {
    text-decoration: underline; }
  .wysiwyg i:not(.fa),
  .wysiwyg cite,
  .wysiwyg em,
  .wysiwyg var,
  .wysiwyg address,
  .wysiwyg dfn,
  .wysiwyg caption {
    font-style: italic; }
  .wysiwyg p,
  .wysiwyg dl,
  .wysiwyg pre,
  .wysiwyg address,
  .wysiwyg hr {
    margin: 1.5em 0; }
  .wysiwyg hr {
    clear: both;
    border-top: 1px solid #adb5bd; }
  .wysiwyg :first-child {
    margin-top: 0; }
  .wysiwyg :last-child {
    margin-bottom: 0; }
  .wysiwyg p:empty {
    display: none; }
  .wysiwyg a:not(.button)[href] {
    color: #008aff;
    text-decoration: underline; }
    .wysiwyg a:not(.button)[href]:hover {
      text-decoration: none; }
  .wysiwyg abbr[title],
  .wysiwyg dfn[title] {
    text-decoration: none;
    border-bottom: 1px dotted;
    cursor: help; }
  .wysiwyg blockquote {
    padding-left: 1.25rem;
    border-left: 5px solid #008aff; }
  .wysiwyg :not(pre) > code,
  .wysiwyg kbd,
  .wysiwyg samp {
    font-family: monospace;
    padding: .1em 6px;
    white-space: normal;
    hyphens: none;
    tab-size: 4;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    text-align: left;
    background-color: #f5f5f6; }
  .wysiwyg table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #e9ecef; }
  .wysiwyg caption {
    padding: .5em;
    caption-side: top;
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
    border-top: 1px solid #e9ecef; }
  .wysiwyg tbody,
  .wysiwyg tfoot,
  .wysiwyg thead,
  .wysiwyg tr,
  .wysiwyg th,
  .wysiwyg td {
    border: inherit; }
  .wysiwyg th,
  .wysiwyg td {
    padding: .5em;
    text-align: left;
    vertical-align: top; }
  .wysiwyg th {
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    background-color: #008aff; }
  .wysiwyg .table-null {
    background-color: transparent; }
    .wysiwyg .table-null caption {
      border-left: none;
      border-right: none;
      border-top: none; }
    .wysiwyg .table-null,
    .wysiwyg .table-null th,
    .wysiwyg .table-null td {
      border-color: transparent; }
    .wysiwyg .table-null th {
      background: transparent; }
  .wysiwyg .table-zebra tbody tr:nth-child(odd) {
    background-color: #e9ecef; }
  .wysiwyg .table-2-columns {
    width: 100%; }
    .wysiwyg .table-2-columns td {
      width: 100%;
      float: left;
      box-sizing: border-box; }
      @media only screen and (min-width: 769px) {
        .wysiwyg .table-2-columns td {
          width: 50%;
          float: none; } }
  .wysiwyg .table-3-columns {
    width: 100%; }
    .wysiwyg .table-3-columns td {
      width: 100%;
      float: left;
      box-sizing: border-box;
      padding-left: 0;
      padding-right: 0; }
      @media only screen and (min-width: 569px) {
        .wysiwyg .table-3-columns td {
          width: 33%;
          float: none;
          padding: 1.25rem; }
          .wysiwyg .table-3-columns td + td {
            border-left: 1px solid #e9ecef; } }
  .wysiwyg .table-4-columns {
    width: 100%; }
    .wysiwyg .table-4-columns td {
      width: 100%;
      float: left;
      box-sizing: border-box;
      vertical-align: top; }
      @media only screen and (min-width: 376px) {
        .wysiwyg .table-4-columns td {
          width: 50%;
          float: left; } }
      @media only screen and (min-width: 769px) {
        .wysiwyg .table-4-columns td {
          width: 25%;
          float: none; } }
  .wysiwyg iframe {
    border-width: 0; }
  .wysiwyg img {
    display: inline; }
    .wysiwyg img.is-full {
      display: block;
      float: none !important;
      margin-left: auto !important;
      margin-right: auto !important; }
  .wysiwyg audio {
    width: 100%; }
  .wysiwyg audio[controls] {
    display: block; }
  .wysiwyg figure {
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
    text-align: center;
    border: 1px solid #e9ecef;
    box-sizing: border-box; }
    .wysiwyg figure p {
      margin: 0; }
  .wysiwyg figcaption {
    padding-top: 0.5rem;
    font-style: italic; }
  .wysiwyg--article {
    max-width: 780px; }

.layout {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; }
  .layout__header {
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 1; }
  .layout__content {
    flex-grow: 1;
    flex-shrink: 0; }
  .layout__footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .layout__video {
    overflow: hidden;
    min-height: 100%; }

.section {
  padding-bottom: 50px; }
  @media only screen and (min-width: 769px) {
    .section {
      padding-bottom: 70px; } }
  @media only screen and (min-width: 1367px) {
    .section {
      padding-bottom: 100px; } }
  .section--video-block {
    height: 100vh;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0; }
    @media only screen and (min-width: 769px) {
      .section--video-block {
        padding-bottom: 0; } }
    @media only screen and (min-width: 1367px) {
      .section--video-block {
        padding-bottom: 0; } }

.container {
  margin: 0 auto;
  width: 100%; }
  .container__wrapper {
    display: flex;
    max-width: 1440px;
    margin: auto;
    padding: 0 15px; }
    @media only screen and (min-width: 1441px) {
      .container__wrapper {
        padding: 0; } }
    .container__wrapper--underline {
      width: 100%;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    .container__wrapper--text {
      max-width: 800px;
      justify-content: center; }
  .container__side {
    flex-shrink: 0;
    padding-left: 20px; }
    @media only screen and (min-width: 769px) {
      .container__side {
        width: 200px;
        padding-left: 40px; } }
    @media only screen and (min-width: 1281px) {
      .container__side {
        width: 300px; } }
    @media only screen and (min-width: 1367px) {
      .container__side {
        padding-left: 70px; } }
  .container__content {
    flex-grow: 1;
    padding-right: 20px; }
    @media only screen and (min-width: 769px) {
      .container__content {
        padding-right: 40px; } }
    @media only screen and (min-width: 1367px) {
      .container__content {
        padding-right: 70px; } }
  .container--default {
    padding-left: 20px;
    padding-right: 20px; }
    @media only screen and (min-width: 769px) {
      .container--default {
        padding-left: 40px;
        padding-right: 40px; } }
    @media only screen and (min-width: 1367px) {
      .container--default {
        padding-left: 70px;
        padding-right: 70px; } }

.header {
  padding-bottom: 30px;
  font-family: Play, sans-serif; }
  @media only screen and (min-width: 1281px) {
    .header {
      padding-top: 20px;
      padding-bottom: 50px; } }
  @media only screen and (min-width: 769px) {
    .header {
      padding-bottom: 70px; } }
  @media only screen and (min-width: 1367px) {
    .header {
      padding-bottom: 100px; } }
  .header__text {
    display: inline-block;
    font-size: 10px;
    color: #ffffff;
    max-width: 120px;
    margin-left: 15px; }
    @media only screen and (min-width: 1281px) {
      .header__text {
        font-size: 12px; } }
  .header__list {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 10px;
    padding-left: 40px;
    padding-bottom: 0;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    background-color: #ffffff;
    height: 300px; }
    @media only screen and (min-width: 1281px) {
      .header__list {
        padding-bottom: 100px;
        height: 350px; } }
    .header__list.is-last {
      left: auto;
      right: 0; }
  .header__link {
    display: block;
    text-decoration: none;
    transition: color 0.3s;
    color: #ffffff;
    line-height: 1.4em;
    padding: 2px 0;
    cursor: pointer;
    font-size: 11px; }
    @media only screen and (min-width: 1281px) {
      .header__link {
        font-size: 14px; } }
    .header__link--arr::after {
      content: '▼';
      display: inline-block;
      vertical-align: bottom;
      font-size: .7rem; }
    .header__link--main {
      padding: 0 0 10px;
      border-bottom: 2px solid transparent;
      transition: color 0.3s;
      font-size: 14px;
      font-weight: normal; }
      @media only screen and (min-width: 1281px) {
        .header__link--main {
          font-size: 16px; } }
      .header__link--main span {
        font-weight: bold; }
    .header__link--sub {
      font-size: 14px;
      line-height: 120%;
      padding: 6px 20px;
      color: black; }
    .header__link--info {
      display: flex;
      position: relative;
      width: 60px;
      height: 60px;
      transition: 0.3s;
      border: 1px solid rgba(255, 255, 255, 0.2); }
      .header__link--info:hover, .header__link--info:focus {
        background-color: #006ed9; }
      .header__link--info span::before {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16L3.82931 14.8242C2.65058 14.1012 1.67256 13.0816 1 11.87L0 16Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 7.99995C0 3.58904 3.5888 0.000389099 8 0.000389099C12.4112 0.000389099 16 3.58904 16 7.99995C16 12.411 12.4112 15.9996 8 15.9996C6.47296 15.9996 5.04448 15.5695 3.82931 14.8242C2.65058 14.1012 1.67256 13.0816 1 11.87C0.362997 10.7225 0 9.40281 0 7.99995ZM8 15.0002C11.866 15.0002 15 11.8662 15 8.00019C15 4.1342 11.866 1.00019 8 1.00019C4.13401 1.00019 1 4.1342 1 8.00019C1 11.8662 4.13401 15.0002 8 15.0002Z' fill='white'/%3E%3Cpath d='M7.99998 6.30343C7.59833 6.30343 7.27271 6.62905 7.27271 7.0307V11.8784C7.27271 12.28 7.59833 12.6056 7.99998 12.6056C8.40163 12.6056 8.72725 12.28 8.72725 11.8784V7.0307C8.72725 6.6291 8.40163 6.30343 7.99998 6.30343Z' fill='white'/%3E%3Cpath d='M7.99998 3.39449C7.80846 3.39449 7.62126 3.47207 7.48555 3.60734C7.35028 3.74261 7.27271 3.93025 7.27271 4.12177C7.27271 4.31328 7.35028 4.50043 7.48555 4.63619C7.62083 4.77146 7.80846 4.84904 7.99998 4.84904C8.19149 4.84904 8.37864 4.77146 8.51435 4.63619C8.64963 4.50043 8.72725 4.31328 8.72725 4.12177C8.72725 3.93025 8.64963 3.74261 8.51435 3.60734C8.37864 3.47202 8.19149 3.39449 7.99998 3.39449Z' fill='white'/%3E%3C/svg%3E%0A");
        display: inline-block;
        position: absolute;
        right: 100%; }
        @media only screen and (min-width: 1025px) {
          .header__link--info span::before {
            margin-right: 10px; } }
      @media only screen and (min-width: 1025px) {
        .header__link--info {
          padding-right: 20px;
          width: auto;
          height: auto;
          border: none; }
          .header__link--info::after {
            content: '';
            margin-left: 20px;
            width: 0;
            height: 12px;
            border: 1px solid rgba(255, 255, 255, 0.2); }
          .header__link--info:hover, .header__link--info:focus {
            background-color: transparent; } }
    .header__link--phone {
      padding: 0 10px;
      background: rgba(255, 255, 255, 0.3);
      margin-left: 8px;
      line-height: 24px; }
    .header__link[href]:hover, .header__link.is-active {
      color: #008aff; }
    .header__link svg {
      width: auto;
      height: auto;
      margin: auto; }
  .header__nav-line {
    display: flex;
    justify-content: space-between;
    padding: 0 -10px;
    flex-wrap: wrap; }
  .header__nav-item {
    z-index: 1;
    padding: 0 10px;
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px; }
    .header__nav-item:hover {
      z-index: 2; }
      .header__nav-item:hover .header__list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start; }
  .header__item {
    padding: 2px 20px 2px 0;
    width: 25%;
    display: flex;
    align-items: center; }
    .header__item::before {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #82C5FF; }
  .header__phones {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: 0.3s;
    width: 60px;
    height: 60px; }
    .header__phones:hover, .header__phones:focus {
      background-color: #006ed9; }
    @media only screen and (min-width: 1025px) {
      .header__phones {
        border: none;
        width: auto;
        height: auto; }
        .header__phones::before {
          content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99988 14.528C8.30036 14.528 8.54395 14.2844 8.54395 13.9839C8.54395 13.6834 8.30036 13.4399 7.99988 13.4399C7.6994 13.4399 7.45581 13.6834 7.45581 13.9839C7.45581 14.2844 7.6994 14.528 7.99988 14.528Z' fill='white'/%3E%3Cpath d='M11.1384 0H4.86144C3.94958 0 3.20782 0.741831 3.20782 1.65362V14.3464C3.20782 15.2582 3.94966 16 4.86144 16H11.1385C12.0503 16 12.7921 15.2582 12.7921 14.3464V1.65362C12.792 0.741831 12.0503 0 11.1384 0ZM4.27449 3.23577H11.7254V12.1173H4.27449V3.23577ZM4.86144 1.06667H11.1385C11.4621 1.06667 11.7254 1.32999 11.7254 1.65362V2.1691H4.27449V1.65362C4.27449 1.32999 4.53774 1.06667 4.86144 1.06667ZM11.1384 14.9333H4.86144C4.53782 14.9333 4.27449 14.67 4.27449 14.3464V13.1839H11.7254V14.3464C11.7254 14.67 11.4621 14.9333 11.1384 14.9333Z' fill='white'/%3E%3C/svg%3E%0A");
          display: inline-block;
          right: 100%; } }
    @media only screen and (min-width: 1025px) and (min-width: 1025px) {
      .header__phones::before {
        margin-right: 12px; } }
    @media only screen and (min-width: 1025px) {
        .header__phones:hover, .header__phones:focus {
          background-color: transparent; } }
    .header__phones--mobile {
      display: flex;
      width: 100%;
      height: 100%; }
      .header__phones--mobile.is-open {
        background-color: #006ed9; }
    .header__phones svg {
      width: auto;
      height: auto;
      margin: auto; }

.footer .logo {
  width: auto; }

.footer__copyright {
  font-family: Play;
  font-size: 13px;
  color: #000000;
  order: -1;
  width: 100%;
  text-align: center;
  margin-bottom: 40px; }
  .footer__copyright--bottom {
    font-family: Roboto;
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.7;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0; }
  @media only screen and (min-width: 1025px) {
    .footer__copyright {
      order: 0;
      width: auto;
      margin-bottom: 0; } }

.link {
  display: inline-block;
  color: #000000;
  font-size: 1.0625rem;
  transition: color 0.3s;
  position: relative;
  padding-bottom: 23px; }
  .link::before, .link::after {
    content: '';
    background-color: #000000;
    transition: color 0.3s;
    position: absolute;
    height: 1px; }
  .link::before {
    width: 100%;
    bottom: 10px;
    left: 0; }
  .link::after {
    width: 15px;
    bottom: 10px;
    right: 0;
    transform: rotate(-45deg);
    transform-origin: 100% 0; }
  .link[href]:hover {
    color: #ffae00; }
    .link[href]:hover::before, .link[href]:hover::after {
      background-color: #ffae00; }

.button {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  fill: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.3em;
  position: relative;
  transition: 0.3s;
  height: 3.75rem;
  min-width: 15.9375rem;
  max-width: 100%;
  padding: 0 1.25rem;
  cursor: default;
  text-align: center;
  overflow: hidden;
  top: 0; }
  .button[href] {
    cursor: pointer; }
  .button span {
    display: inline-block; }
    .button span + svg {
      margin-left: 10px; }
  .button svg {
    width: 1.5625rem;
    height: 1.5625rem; }
    .button svg + span {
      margin-left: 10px; }
  .button:hover, .button:focus {
    background-color: #006ed9; }
  .button:active {
    top: 5px; }
  .button--disabled, .button[disabled], .button.is-disabled {
    cursor: not-allowed;
    opacity: 0.75;
    filter: grayscale(1); }
    .button--disabled:active, .button[disabled]:active, .button.is-disabled:active {
      top: 0; }
  .button--full {
    width: 100%;
    min-width: 0; }
  .button--icon {
    min-width: 3.75rem;
    padding: 0; }
    .button--icon .close {
      display: none; }
    .button--icon.is-open {
      background-color: #006ed9; }
      .button--icon.is-open .close {
        display: block; }
      .button--icon.is-open .open {
        display: none; }

.title {
  color: #000000;
  font-weight: 700; }

.title--h1 {
  font-size: 2.25rem;
  line-height: 1.1em; }
  @media only screen and (min-width: 641px) {
    .title--h1 {
      font-size: 2.625rem; } }
  @media only screen and (min-width: 1281px) {
    .title--h1 {
      font-size: 3.125rem; } }

.title--h2 {
  font-size: 1.875rem;
  line-height: 1.2em; }
  @media only screen and (min-width: 641px) {
    .title--h2 {
      font-size: 2.25rem; } }
  @media only screen and (min-width: 1281px) {
    .title--h2 {
      font-size: 2.625rem; } }

.title--h3 {
  font-size: 1.5rem; }
  @media only screen and (min-width: 641px) {
    .title--h3 {
      font-size: 2rem; } }

.title--h4 {
  font-size: 1.5rem; }

.title--h5 {
  font-size: 1.3125rem; }

.title--h6 {
  font-size: 1.125rem; }

.form {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: #fff; }
  .form form {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: 0; }
    .form form > * {
      transition-property: opacity, filter;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      will-change: opacity, filter; }
    .form form.is-pending::before, .form form.is-pending::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      z-index: 4;
      display: block;
      border: 4px solid #008aff;
      border-radius: 50%; }
    .form form.is-pending::before {
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      width: 60px;
      height: 60px;
      border-left-color: rgba(0, 138, 255, 0.001);
      border-bottom-width: 0;
      animation: rotating 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite; }
    .form form.is-pending::after {
      left: calc(50% - 20px);
      top: calc(50% - 20px);
      width: 40px;
      height: 40px;
      border-right-width: 0;
      border-top-color: rgba(0, 138, 255, 0.001);
      animation: rotating 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite reverse; }
    .form form.is-pending > * {
      opacity: .3;
      pointer-events: none;
      filter: grayscale(1); }

@keyframes rotating {
  0% {
    transform: rotate(0deg) translateZ(0); }
  100% {
    transform: rotate(360deg) translateZ(0); } }

.control {
  position: relative;
  display: block; }
  .control__inner {
    position: relative;
    display: block; }
    .control__inner::before, .control__inner::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #cbcbcb;
      transition: transform 0.2s ease;
      transform-origin: left;
      will-change: transform; }
    .control__inner::before {
      z-index: 1; }
    .control__inner::after {
      z-index: 2;
      background-color: #008aff;
      transform: scaleX(0); }
  .control__label {
    display: block;
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer; }
  .control__field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 3.125rem;
    padding: 0;
    background-color: #ffffff;
    border: none;
    color: #000000;
    font-size: 1rem;
    font-family: Arial, sans-serif;
    line-height: 3.125rem;
    outline: none;
    transition: border-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
    will-change: border-color, box-shadow; }
    .control__field::placeholder {
      color: #cbcbcb;
      font-size: 1rem;
      transition: color 0.2s ease;
      will-change: color; }
    .control__field:-webkit-autofill ~ .control__label {
      top: -2px;
      font-size: 12px; }
  .control--input .control__label, .control--textarea .control__label {
    position: absolute;
    top: calc((3.125rem / 2) - (0.40625rem));
    left: 0;
    z-index: 1;
    transition-property: top, font-size;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    will-change: top, font-size; }
  .control--textarea .control__field {
    min-height: 100px;
    padding: 1.25rem 0;
    line-height: 1;
    resize: none; }
  .control--select .control__field {
    border-bottom: 1px solid #cbcbcb;
    padding-right: 38px;
    background-image: url(/static/pic/icon-arrow-bottom-thin.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) 50%;
    background-size: 1.2rem;
    font-size: 1rem;
    appearance: none !important; }
    .control--select .control__field::-ms-expand {
      display: none; }
    .control--select .control__field option {
      color: #000000;
      font-size: 1.1em; }
      .control--select .control__field option[disabled] {
        display: none; }
  .control--select select.s2s {
    height: 3.125rem;
    min-height: 3.125rem;
    max-height: 3.125rem !important;
    overflow: hidden !important;
    background-color: transparent !important;
    color: transparent !important;
    border: none;
    border-bottom: 1px solid #cbcbcb;
    width: 100%; }
    .control--select select.s2s.select2-hidden-accessible {
      width: 0 !important;
      min-height: 0 !important;
      height: 0 !important;
      line-height: 0 !important;
      visibility: hidden !important;
      z-index: -1 !important;
      border-bottom: none !important; }
    .control--select select.s2s.has-error ~ .select2 .s2s-container.s2s-container--default {
      border-color: #db402c !important; }
      .control--select select.s2s.has-error ~ .select2 .s2s-container.s2s-container--default .select2-selection__arrow b {
        border-top-color: #db402c !important; }
      .select2-container--open .control--select select.s2s.has-error ~ .select2 .s2s-container.s2s-container--default {
        border-color: #db402c !important; }
        .select2-container--open .control--select select.s2s.has-error ~ .select2 .s2s-container.s2s-container--default .select2-selection__arrow b {
          border-bottom-color: #db402c !important; }
  .control--checkbox, .control--radio {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.01);
    cursor: default; }
    .control--checkbox .control__field, .control--radio .control__field {
      position: absolute !important;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px !important;
      width: 1px !important;
      margin: -1px;
      padding: 0 !important;
      border: none !important;
      overflow: hidden; }
    .control--checkbox .control__icon, .control--radio .control__icon {
      flex-shrink: 0;
      position: relative;
      display: block;
      width: 16px;
      height: 16px;
      background-color: #ffffff;
      border: 1px solid #cbcbcb;
      transition: border-color 0.2s ease, background-color 0.2s ease; }
      .control--checkbox .control__icon::after, .control--radio .control__icon::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: block;
        margin: auto;
        transform-origin: center;
        transition: 0.2s ease; }
      .control--checkbox .control__icon + .control__text, .control--radio .control__icon + .control__text {
        padding-left: 10px; }
    .control--checkbox .control__text, .control--radio .control__text {
      color: #000000;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      user-select: none;
      line-height: 1em; }
      .control--checkbox .control__text a, .control--radio .control__text a {
        color: #008aff;
        text-decoration: underline; }
        .control--checkbox .control__text a:hover, .control--radio .control__text a:hover {
          text-decoration: none; }
  .control--checkbox .control__field:checked ~ .control__icon {
    background-color: #008aff;
    border-color: #008aff; }
    .control--checkbox .control__field:checked ~ .control__icon::after {
      opacity: 1; }
  .control--checkbox .control__icon::after {
    top: -6px;
    width: 8px;
    height: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent transparent #ffffff #ffffff;
    transform: rotate(-45deg) skew(-10deg, -5deg);
    opacity: 0; }
  .control--radio .control__field:checked ~ .control__icon {
    border-color: #008aff; }
    .control--radio .control__field:checked ~ .control__icon::after {
      transform: scale(1);
      opacity: 1; }
  .control--radio .control__icon {
    border-radius: 50%; }
    .control--radio .control__icon::after {
      width: 8px;
      height: 8px;
      border-radius: inherit;
      background-color: #008aff;
      transform: scale(0.5);
      opacity: 0; }
  .control--file .control__item {
    transition: border-color 0.3s;
    color: #000000;
    font-size: 1rem;
    line-height: 3.125rem;
    width: 100%;
    height: 3.125rem;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    user-select: none; }
    .control--file .control__item.is-placeholder {
      color: #cbcbcb; }
  .control--file .control__field {
    position: absolute;
    width: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
    z-index: -1; }
  .control.in-focus.control--input .control__inner::after, .control.in-focus.control--textarea .control__inner::after, .control.has-value.control--input .control__inner::after, .control.has-value.control--textarea .control__inner::after {
    transform: scaleX(1); }
  .control.in-focus.control--input .control__label, .control.in-focus.control--textarea .control__label, .control.has-value.control--input .control__label, .control.has-value.control--textarea .control__label {
    top: 0;
    font-size: 0.8125rem;
    line-height: 1em; }
  .control.in-focus.control--input .control__field, .control.has-value.control--input .control__field {
    padding-top: 0.8125rem; }
  .control.in-focus.control--textarea .control__field, .control.has-value.control--textarea .control__field {
    padding-top: 1.4375rem; }
  .control:-webkit-autofill.control--input .control__inner::after, .control:-webkit-autofill.control--textarea .control__inner::after {
    transform: scaleX(1); }
  .control:-webkit-autofill.control--input .control__label, .control:-webkit-autofill.control--textarea .control__label {
    top: -2px;
    font-size: 12px; }

label.has-error {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 0.6875rem;
  background: #ffffff;
  color: #db402c;
  padding: 4px 8px;
  border: 1px solid;
  margin-top: 5px;
  z-index: 9; }
  label.has-error::after, label.has-error::before {
    bottom: 100%;
    left: 0.625rem;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  label.has-error::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 4px;
    margin-left: -4px; }
  label.has-error::before {
    border-color: rgba(219, 64, 44, 0);
    border-bottom-color: #db402c;
    border-width: 5px;
    margin-left: -5px; }

.range-input {
  display: block;
  position: relative; }
  .range-input__value {
    width: 100%;
    height: 3.125rem;
    padding: 0 0.5rem 0 2rem;
    border: 1px solid #dee2e6;
    font-size: 1rem; }
  .range-input__label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;
    font-size: 1rem;
    color: #cbcbcb; }

.logo {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 60px; }
  .logo img {
    max-height: 100%; }
  @media only screen and (min-width: 1025px) {
    .logo {
      padding-left: 0;
      display: block;
      border: none;
      width: auto;
      height: auto;
      max-width: 100%; } }

.breadcrumbs {
  display: flex; }
  .breadcrumbs__item {
    display: block; }
    .breadcrumbs__item:not(:nth-last-child(2)) {
      display: none; }
      @media only screen and (min-width: 1026px) {
        .breadcrumbs__item:not(:nth-last-child(2)) {
          display: block; } }
    .breadcrumbs__item--last {
      flex: 1 1 0%;
      overflow: hidden; }
  .breadcrumbs__link {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap; }
    .breadcrumbs__link--link {
      display: block;
      color: #8a8a8a;
      text-decoration: underline;
      margin-left: 1.875rem; }
      @media only screen and (min-width: 1026px) {
        .breadcrumbs__link--link {
          margin-left: 0;
          margin-right: 1.875rem; } }
      .breadcrumbs__link--link::after {
        content: '';
        border-top: 1px solid;
        border-right: 1px solid;
        width: 7px;
        height: 7px;
        position: absolute;
        top: 6px;
        transform: rotate(-135deg);
        left: -1rem;
        pointer-events: none; }
        @media only screen and (min-width: 1026px) {
          .breadcrumbs__link--link::after {
            left: auto;
            right: -1rem;
            transform: rotate(45deg); } }
      .breadcrumbs__link--link:hover {
        color: #008aff;
        text-decoration: none; }
      .breadcrumbs__link--link:active {
        color: #006ed9; }
    .breadcrumbs__link--last {
      max-width: 100%;
      color: #a3a3a3;
      overflow: hidden;
      text-overflow: ellipsis; }

.scroll-down {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 20px;
  position: absolute;
  bottom: 20px; }
  .scroll-down::after {
    content: '';
    border: solid #919398;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    position: relative;
    top: -4px;
    animation: blinker 3s linear infinite; }
  .scroll-down__line {
    width: 2px;
    height: 3.125rem;
    display: inline-block;
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    overflow: hidden; }
    .scroll-down__line::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: top center;
      animation: scroll-move 3s linear infinite;
      height: 50%;
      width: 100%;
      z-index: 2;
      background-color: #f5f5f6; }
    .scroll-down__line:hover {
      color: #000000; }

@keyframes scroll-move {
  0% {
    transform: translateY(0%) scaleY(0); }
  10% {
    transform: translateY(0%) scaleY(0.5); }
  50% {
    transform: translateY(50%) scaleY(1); }
  90% {
    transform: translateY(200%) scaleY(0.5); }
  100% {
    transform: translateY(200%) scaleY(0); } }

@keyframes blinker {
  from {
    border-color: #919398; }
  to {
    border-color: #f5f5f6; } }

.scroll-up {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 2.5rem;
  z-index: 100;
  visibility: hidden; }
  @media only screen and (min-width: 1367px) {
    .scroll-up {
      width: 6.25rem; } }
  .scroll-up__text {
    color: #000000;
    text-transform: uppercase;
    font-size: 12px;
    padding-right: 0.625rem;
    letter-spacing: 1px;
    transition: color 0.3s ease; }
  .scroll-up__arrow {
    display: block;
    vertical-align: top;
    position: relative;
    transition: width 0.3s ease;
    height: 2px;
    margin: 5px 5px 7px 0;
    width: 2.25rem;
    background: #000000; }
    .scroll-up__arrow::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      margin-left: -7px;
      border: 2px solid;
      border-bottom-width: 0;
      border-left-width: 0;
      border-color: #000000;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      transform-origin: top right; }
  .scroll-up__content {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: rotate(-90deg) translateY(50%);
    white-space: nowrap;
    transform-origin: 0 100%;
    user-select: none;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    transition: opacity 0.3s ease; }
    .scroll-up__content:hover .scroll-up__text {
      color: #008aff; }
    .scroll-up__content:hover .scroll-up__arrow {
      width: 4.375rem; }
    .scroll-up__content.is-show {
      opacity: 1;
      visibility: visible; }

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 60px;
  left: 0;
  z-index: -1; }
  .mobile-menu__container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    overflow-x: hidden;
    transition: transform 0.5s ease-out;
    transform: translateX(100%); }
    @media only screen and (min-width: 641px) {
      .mobile-menu__container {
        width: 50%; } }
    @media only screen and (min-width: 1281px) {
      .mobile-menu__container {
        width: 30%; } }
  .mobile-menu__block {
    position: relative; }
  .mobile-menu__link {
    display: block;
    color: #000000;
    font-family: FuturaPT-Demi, Arial, sans-serif;
    line-height: 1.5em;
    transition: 0.3s;
    border-bottom: 1px solid #CACBCE;
    padding: 20px 50px; }
    .mobile-menu__link::first-line {
      font-weight: bold; }
    .mobile-menu__link::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #82C5FF;
      display: inline-block;
      margin-right: 20px; }
    .mobile-menu__link:hover, .mobile-menu__link.is-active {
      color: #ffffff;
      background-color: #242A3A;
      cursor: pointer; }
    .mobile-menu__link--main {
      position: absolute;
      width: 30px;
      right: 0;
      display: flex;
      align-items: center;
      margin-top: -32px; }
      .mobile-menu__link--main::before {
        content: url("data:image/svg+xml,%3Csvg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM4 10.5L2 10.5L2 13.5L4 13.5L4 10.5Z' fill='%2382C5FF'/%3E%3C/svg%3E%0A");
        position: absolute;
        height: 100%;
        display: flex;
        transform: rotate(180deg);
        align-items: center;
        cursor: pointer; }
      .mobile-menu__link--main.is-active + .mobile-menu__link--sub {
        transform: translateX(0); }
    .mobile-menu__link--sub {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color: #fff;
      overflow-x: hidden;
      transition: transform 0.5s ease-out;
      transform: translateX(100%);
      text-align: center;
      z-index: 2; }
      .mobile-menu__link--sub .mobile-menu__link::before {
        display: none; }
    .mobile-menu__link--title {
      background: #EBF0F5;
      position: relative; }
      .mobile-menu__link--title::before {
        content: url("data:image/svg+xml,%3Csvg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM4 10.5L2 10.5L2 13.5L4 13.5L4 10.5Z' fill='%2382C5FF'/%3E%3C/svg%3E%0A");
        display: inline-block !important;
        margin-right: 20px;
        background: transparent;
        position: absolute;
        left: 30px;
        width: auto;
        height: auto; }
    @media xxs {
      .mobile-menu__link {
        padding: 40px 50px; } }
  .mobile-menu__bg {
    background-color: #000000;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-out; }
  .mobile-menu.is-open {
    z-index: 1000; }
  .mobile-menu.is-opening .mobile-menu__container {
    transform: translateX(0); }
  .mobile-menu.is-opening .mobile-menu__bg {
    opacity: 0.6; }

.mobile-phone {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; }
  .mobile-phone__container {
    font-family: Play;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    width: 100%;
    max-width: 350px;
    background-color: #fff;
    overflow-x: hidden;
    transition: transform 0.5s ease-out;
    transform: scale(0);
    padding: 20px 40px;
    color: #242A3A; }
    .is-opening .mobile-phone__container {
      transform: scale(1); }
  .mobile-phone__block {
    position: relative; }
    .mobile-phone__block + .mobile-phone__block {
      margin-top: 30px; }
  .mobile-phone__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px; }
  .mobile-phone__time {
    font-size: 12px;
    line-height: 16px;
    color: #B9BBBD;
    margin-bottom: 15px; }
  .mobile-phone__text {
    font-size: 18px;
    line-height: 30px;
    color: #242A3A;
    margin-bottom: 15px; }
  .mobile-phone__link {
    display: block;
    font-size: 20px;
    line-height: 32px;
    color: #000000; }
  .mobile-phone__bg {
    background-color: #000000;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-out; }
  .mobile-phone.is-open {
    z-index: 1000; }
  .mobile-phone.is-opening .mobile-phone__bg {
    opacity: 0.6; }
  .mobile-phone__close {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    fill: #000000;
    transition: fill 0.3s ease-out;
    position: absolute;
    top: 0;
    right: 0; }
    .mobile-phone__close svg {
      width: 70%;
      height: 70%; }
    .mobile-phone__close:hover {
      fill: #008aff; }

.global-msg {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  clear: both;
  font-size: 14px;
  line-height: 1.3em;
  backface-visibility: hidden;
  outline: none;
  padding: 0;
  margin: 0;
  border-width: 0; }
  .global-msg--top {
    top: 0; }
  .global-msg--bottom {
    bottom: 0; }
  .global-msg__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .global-msg__input:checked + .global-msg__container {
    display: none; }
  .global-msg__container {
    padding: .75em 1.5em;
    background-color: #737373;
    color: #ffffff; }
    .global-msg__container::before, .global-msg__container::after {
      content: '';
      display: table; }
    .global-msg__container::after {
      clear: both; }
  .global-msg__close {
    display: block;
    float: right;
    width: 1em;
    height: 1em;
    font-size: 3em;
    line-height: 1;
    margin-left: .5em;
    text-align: center;
    cursor: pointer; }
    .global-msg__close:hover {
      background-color: rgba(0, 0, 0, 0.075); }
    .global-msg__close:active {
      background-color: rgba(0, 0, 0, 0.15); }
  .global-msg__content {
    overflow: hidden; }
    .global-msg__content p {
      margin: 0.5em 0; }
    .global-msg__content b {
      font-weight: bold; }

img[data-zzload-source-img] {
  opacity: 0;
  transition: opacity 0.3s; }
  img[data-zzload-source-img][data-zzload-is-loaded] {
    opacity: 1;
    width: auto; }

.draggable-table {
  position: relative;
  overflow: hidden; }
  .draggable-table__holder {
    cursor: initial !important;
    user-select: initial !important; }
    .draggable-table--left .draggable-table__holder,
    .draggable-table--right .draggable-table__holder {
      cursor: move !important; }
  .draggable-table::before, .draggable-table::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1.5rem;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 99; }
  .draggable-table::before {
    right: 100%;
    box-shadow: 10px 0 8px -6px #bfbfbf; }
  .draggable-table::after {
    left: 100%;
    box-shadow: -10px 0 8px -6px #bfbfbf; }
  .draggable-table--left::before, .draggable-table--right::after {
    opacity: .3; }

.timer-container {
  text-align: center;
  width: 17.1875rem;
  max-width: 100%; }

.timer-text {
  font-size: 12px;
  color: #bfbfbf;
  margin-bottom: 5px; }

.timer.timeTo.timeTo-white div {
  background: none;
  color: #fafafa;
  border: none !important; }

.timer.timeTo > span {
  display: none; }

.timer.timeTo > figure {
  position: relative;
  text-align: center;
  padding-right: 11px;
  width: auto !important;
  max-width: initial !important; }
  .timer.timeTo > figure > div {
    display: inline-block;
    margin-right: 0 !important; }
  .timer.timeTo > figure > figcaption {
    padding-right: 0 !important; }
  .timer.timeTo > figure::after {
    content: ':';
    position: absolute;
    top: -2px;
    right: 0;
    color: #fafafa; }
  .timer.timeTo > figure:nth-last-child(3)::after {
    display: none; }
  .timer.timeTo > figure:last-child {
    display: none; }

.ratio {
  position: relative; }
  .ratio--1x1 {
    padding-top: 100%; }
  .ratio--16x9 {
    padding-top: 56.25%; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.motion-text {
  position: relative; }
  .motion-text__text {
    font-family: FuturaPT-Demi, Arial, sans-serif;
    font-size: 30px;
    line-height: 1.2em;
    text-transform: uppercase; }
    @media only screen and (min-width: 481px) {
      .motion-text__text {
        font-size: 40px; } }
    @media only screen and (min-width: 769px) {
      .motion-text__text {
        font-size: 50px; } }
    @media only screen and (min-width: 1367px) {
      .motion-text__text {
        font-size: 50px; } }
  .motion-text__image {
    display: none; }
  .motion-text__word {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    user-select: none; }
    @media only screen and (min-width: 769px) {
      .motion-text__word {
        justify-content: flex-start; } }
    .motion-text__word:not(:last-child) {
      margin-right: 10px; }
      @media only screen and (min-width: 769px) {
        .motion-text__word:not(:last-child) {
          margin-right: 1.875rem; } }
    .motion-text__word.is-active .motion-text__text {
      cursor: default;
      text-decoration: underline; }
      .motion-text__word.is-active .motion-text__text:hover {
        color: #008aff;
        text-decoration: none !important; }
  .motion-text__canvas {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    will-change: transform;
    transform: translateZ(0); }

.video-block {
  position: relative;
  z-index: -1; }
  .video-block__video {
    max-width: none; }
  .video-block__wrapper {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(36, 43, 58, 0.5); }
  .video-block__text {
    max-width: calc(100% - 20px);
    color: #fff;
    font-family: FuturaPT-Demi, Arial, sans-serif;
    font-size: 50px;
    line-height: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }
    @media only screen and (min-width: 769px) {
      .video-block__text {
        font-size: 60px; } }
    @media only screen and (min-width: 1281px) {
      .video-block__text {
        font-size: 80px; } }
  .video-block--desktop {
    display: none; }
    @media only screen and (min-width: 1025px) {
      .video-block--desktop {
        display: block; } }
  .video-block--mobile {
    display: block; }
    @media only screen and (min-width: 1025px) {
      .video-block--mobile {
        display: none; } }

.services__title {
  font-family: FuturaPT-Demi, Arial, sans-serif;
  font-size: 30px;
  line-height: 1em;
  text-transform: uppercase;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center; }
  @media only screen and (min-width: 481px) {
    .services__title {
      font-size: 40px; } }
  @media only screen and (min-width: 769px) {
    .services__title {
      font-size: 50px;
      padding-top: 70px;
      padding-bottom: 70px;
      text-align: left; } }
  @media only screen and (min-width: 1367px) {
    .services__title {
      font-size: 50px;
      padding-top: 70px;
      padding-bottom: 70px; } }

.services__image {
  position: relative;
  max-width: 425px;
  width: 100%; }
  @media only screen and (max-width: 1366px) {
    .services__image {
      text-align: center; } }
  .services__image img {
    width: 100% !important;
    max-height: 544px;
    height: 100%; }
    @media screen and (max-width: 1561px) {
      .services__image img {
        height: auto; } }
    @media only screen and (max-width: 1366px) {
      .services__image img {
        display: inline-block;
        width: 420px !important;
        max-height: 544px; } }
    @media only screen and (max-width: 480px) {
      .services__image img {
        width: 100% !important; } }
  @media only screen and (min-width: 1367px) {
    .services__image--offset {
      margin-top: 125px; } }

.services__content {
  display: flex;
  justify-content: center; }

.services__block {
  position: relative;
  padding-top: 20px; }
  @media only screen and (min-width: 481px) {
    .services__block {
      max-width: 420px; } }
  @media only screen and (min-width: 1367px) {
    .services__block {
      padding-top: 110px; } }
  .services__block::before {
    content: '';
    width: 5.3125rem;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15); }
  @media only screen and (max-width: 1366px) {
    .services__block {
      text-align: center; }
      .services__block::before {
        left: 50%;
        transform: translateX(-50%); } }

.services__name {
  color: #000000;
  font-family: FuturaPT-Demi, Arial, sans-serif;
  font-size: 20px;
  margin-bottom: 20px; }
  @media only screen and (min-width: 769px) {
    .services__name {
      font-size: 30px;
      margin-bottom: 50px; } }
  @media only screen and (min-width: 1367px) {
    .services__name {
      font-size: 50px; } }

.services__text {
  margin-bottom: 20px; }
  @media only screen and (min-width: 769px) {
    .services__text {
      margin-bottom: 50px; } }

.services__list {
  display: flex;
  flex-wrap: wrap; }
  .services__list + .services__list {
    padding-top: 50px; }
    @media only screen and (min-width: 1367px) {
      .services__list + .services__list {
        padding-top: 130px; } }

.services__item {
  width: 100%; }
  @media only screen and (min-width: 1367px) {
    .services__item {
      width: 50%; } }
  @media only screen and (min-width: 1367px) {
    .services__item--first {
      padding-right: 1.5625rem;
      width: 230px; } }
  @media only screen and (min-width: 1561px) {
    .services__item--first {
      width: 450px; } }
  @media only screen and (min-width: 1367px) {
    .services__item--second {
      width: calc((100% - 230px) / 2);
      padding-right: 1.5625rem; } }
  @media only screen and (min-width: 1561px) {
    .services__item--second {
      width: calc((100% - 450px) / 2); } }
  @media only screen and (min-width: 1367px) {
    .services__item--third {
      width: calc((100% - 230px) / 2);
      padding-left: 1.5625rem; } }
  @media only screen and (min-width: 1561px) {
    .services__item--third {
      width: calc((100% - 450px) / 2); } }
  @media only screen and (max-width: 1366px) {
    .services__item--text {
      order: 1;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 1366px) {
    .services__item--image {
      width: 50% !important;
      order: 2;
      margin-bottom: 30px;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }
  @media only screen and (max-width: 640px) {
    .services__item--image {
      width: 100% !important;
      padding-left: 0;
      padding-right: 0; } }

.services__canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: none;
  will-change: transform;
  transform: translateZ(0); }

.line {
  height: 1px;
  position: relative; }
  .line::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    transition: width 2s; }
  .line.is-show::before {
    width: 100%; }

.move-text {
  padding-top: 20px;
  overflow: hidden; }
  @media only screen and (min-width: 769px) {
    .move-text {
      padding-top: 50px; } }
  @media only screen and (min-width: 1367px) {
    .move-text {
      padding-top: 120px; } }
  .move-text__block {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    animation-duration: 80s;
    animation-timing-function: linear; }
    .move-text__block--1 {
      animation-iteration-count: infinite; }
    .move-text__block--2 {
      animation-iteration-count: infinite;
      animation-delay: 40s; }
    .move-text__block--3 {
      animation-iteration-count: 1; }
  .move-text__item {
    display: flex;
    align-items: center;
    cursor: default; }
    .move-text__item + .move-text__item {
      margin-top: 1.25rem; }
      @media only screen and (min-width: 769px) {
        .move-text__item + .move-text__item {
          margin-top: 1.875rem; } }
    .move-text__item--reverse {
      direction: rtl; }
  .move-text__word {
    flex-shrink: 0;
    margin-right: 3.125rem; }
    @media only screen and (min-width: 769px) {
      .move-text__word {
        margin-right: 6.25rem; } }
    @media only screen and (min-width: 1367px) {
      .move-text__word {
        margin-right: 9.75rem; } }
  .move-text__text {
    font-size: 30px;
    line-height: 1.2em;
    text-transform: uppercase;
    color: #ffffff;
    -webkit-text-stroke: 1px #000000;
    white-space: nowrap;
    transition: color 0.3s; }
    @media only screen and (min-width: 481px) {
      .move-text__text {
        font-size: 50px; } }
    @media only screen and (min-width: 769px) {
      .move-text__text {
        font-size: 75px; } }
    @media only screen and (min-width: 1367px) {
      .move-text__text {
        -webkit-text-stroke: 2px #000000;
        font-size: 110px; } }
    .move-text__text--light {
      color: transparent;
      -webkit-text-stroke: 1px #ffffff; }
      @media only screen and (min-width: 1367px) {
        .move-text__text--light {
          -webkit-text-stroke: 2px #ffffff; } }
    .move-text__text:hover {
      color: #008aff;
      -webkit-text-stroke: 1px #008aff; }
      @media only screen and (min-width: 1367px) {
        .move-text__text:hover {
          -webkit-text-stroke: 2px #008aff; } }
  .move-text__icon {
    width: 1.25rem;
    height: 1.25rem;
    background-color: #008aff;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 3.125rem; }
    @media only screen and (min-width: 769px) {
      .move-text__icon {
        margin-right: 6.25rem;
        width: 1.875rem;
        height: 1.875rem; } }
    @media only screen and (min-width: 1367px) {
      .move-text__icon {
        margin-right: 9.75rem;
        width: 3.0625rem;
        height: 3.0625rem; } }
  .move-text__image {
    display: none; }
  .move-text__canvas {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none; }
  .move-text.is-active {
    opacity: 1; }
    .move-text.is-active .move-text__block--1 {
      animation-iteration-count: infinite;
      animation-name: marquee; }
    .move-text.is-active .move-text__block--2 {
      animation-iteration-count: infinite;
      animation-delay: 40s;
      animation-name: marquee2; }
    .move-text.is-active .move-text__block--3 {
      animation-iteration-count: 1;
      animation-name: marquee3; }
    .move-text.is-active .move-text__item--reverse {
      direction: rtl; }
      .move-text.is-active .move-text__item--reverse .move-text__block--1 {
        animation-name: marquee-rev; }
      .move-text.is-active .move-text__item--reverse .move-text__block--2 {
        animation-name: marquee2-rev; }
      .move-text.is-active .move-text__item--reverse .move-text__block--3 {
        animation-name: marquee3-rev; }

@keyframes marquee {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(-100%); } }

@keyframes marquee2 {
  from {
    transform: translateX(0%); }
  to {
    transform: translateX(-200%); } }

@keyframes marquee3 {
  from {
    transform: translateX(-200%); }
  to {
    transform: translateX(-400%); } }

@keyframes marquee-rev {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(100%); } }

@keyframes marquee2-rev {
  from {
    transform: translateX(0%); }
  to {
    transform: translateX(200%); } }

@keyframes marquee3-rev {
  from {
    transform: translateX(200%); }
  to {
    transform: translateX(400%); } }

.static-text {
  color: #000000;
  font-family: FuturaPT-Demi, Arial, sans-serif;
  font-size: 30px;
  line-height: 1.2em;
  text-transform: uppercase;
  text-align: center; }
  @media only screen and (min-width: 769px) {
    .static-text {
      font-size: 50px;
      text-align: left; } }
  @media only screen and (min-width: 1367px) {
    .static-text {
      font-size: 90px; } }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-resizer {
  display: none; }

::-webkit-scrollbar-thumb {
  border-radius: 0;
  transition: background-color 0.3s; }

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2); }

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8); }
  ::-webkit-scrollbar-thumb:hover {
    background-color: black; }

::-webkit-scrollbar {
  width: 8px; }

.contact-info {
  padding-top: 50px; }
  @media only screen and (min-width: 769px) {
    .contact-info {
      padding-top: 70px; } }
  @media only screen and (min-width: 1367px) {
    .contact-info {
      padding-top: 70px; } }
  .contact-info__text {
    color: #000000;
    font-size: 1.125rem;
    margin-bottom: 25px;
    text-align: center;
    font-family: FuturaPT-Demi, Arial, sans-serif; }
    @media only screen and (min-width: 769px) {
      .contact-info__text {
        text-align: left; } }
    @media only screen and (min-width: 1367px) {
      .contact-info__text {
        margin-bottom: 30px; } }
  .contact-info__link {
    color: #000000;
    font-size: 1.25rem;
    transition: color 0.3s;
    text-decoration: none;
    font-family: FuturaPT-Demi, Arial, sans-serif; }
    @media only screen and (min-width: 1367px) {
      .contact-info__link {
        font-size: 2.25rem; } }
    .contact-info__link[href]:hover {
      color: #008aff; }

.animate-block {
  position: relative;
  overflow: hidden; }
  .animate-block__image {
    min-height: 200px;
    background-color: #737373; }
    .animate-block__image img {
      width: 100% !important; }
  .animate-block__text {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }

.error__title {
  font-family: FuturaPT-Demi, Arial, sans-serif;
  font-size: 100px;
  line-height: 1.2em;
  text-transform: uppercase; }
  @media only screen and (min-width: 1367px) {
    .error__title {
      font-size: 150px; } }

.services-page__block {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 66vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1; }

.services-page__center {
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 66vh;
  padding-top: 100px; }

.services-page__title {
  font-family: FuturaPT-Demi, Arial, sans-serif;
  line-height: 35px;
  color: #FFFFFF;
  font-size: 35px; }
  @media only screen and (min-width: 569px) {
    .services-page__title {
      line-height: 50px;
      font-size: 50px; } }
  @media only screen and (min-width: 1281px) {
    .services-page__title {
      line-height: 80px;
      font-size: 80px; } }
  .services-page__title--sup {
    font-family: Play, sans-serif;
    color: #82C5FF;
    text-transform: uppercase;
    font-size: 16px; }

.services-page__content {
  padding-top: 60vh; }
  @media only screen and (min-width: 1025px) {
    .services-page__content {
      padding-top: 50vh; } }
  @media only screen and (min-width: 1281px) {
    .services-page__content {
      padding-top: 40vh; } }
  .services-page__content--sup-title {
    font-family: Play;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #82C5FF; }
  .services-page__content--title {
    font-family: FuturaPT-Demi;
    font-size: 40px;
    line-height: 40px;
    color: #000; }
  .services-page__content--text {
    font-family: Play;
    font-size: 18px;
    line-height: 30px; }
  .services-page__content--text-custom {
    font-family: Play;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 5px solid black;
    padding-top: 30px;
    padding-bottom: 30px; }
  .services-page__content--phone {
    font-family: Play;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    margin: 10px 5px; }
    @media only screen and (min-width: 569px) {
      .services-page__content--phone {
        margin: 0 10px; }
        .services-page__content--phone:nth-child(2)::before {
          left: 33%; }
        .services-page__content--phone:nth-child(2)::after {
          right: 33%; }
        .services-page__content--phone:nth-child(2)::before, .services-page__content--phone:nth-child(2)::after {
          content: '';
          position: absolute;
          top: 10px;
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #82C5FF; } }
  .services-page__content .info-block {
    font-family: Play;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #82C5FF;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center; }
    .services-page__content .info-block span {
      width: 100%; }
    .services-page__content .info-block::before, .services-page__content .info-block::after {
      content: '';
      display: flex;
      width: 100%;
      height: 2px;
      background: #82C5FF; }

.services-page__image-block {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.570466) 10%, #f7f8fb 10%, #f7f8fb 90%, white 90%);
  height: auto;
  width: 100%;
  margin-bottom: 60px; }
  @media only screen and (min-width: 1025px) {
    .services-page__image-block {
      margin-top: 75px;
      height: 484px; } }

.services-page__image {
  display: block;
  position: relative;
  max-width: 50%; }
  @media only screen and (min-width: 1367px) {
    .services-page__image {
      max-width: 650px; } }
  .services-page__image--first {
    top: -25px; }
    @media only screen and (min-width: 641px) {
      .services-page__image--first {
        top: -75px; } }
  .services-page__image--second {
    top: 30px; }
    @media only screen and (min-width: 641px) {
      .services-page__image--second {
        top: 60px; } }

.services-page .button {
  border-color: white; }

.similar-item {
  padding: 90px 0;
  text-align: center;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important; }
  @media only screen and (min-width: 641px) {
    .similar-item {
      padding: 130px 0; } }
  .similar-item__sup-title {
    font-family: Play;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #82C5FF; }
  .similar-item__title {
    font-family: FuturaPT-Demi;
    font-size: 2rem;
    text-align: center;
    color: #FFFFFF;
    height: 5.5rem;
    overflow: hidden;
    text-overflow: ellipsis; }
  .similar-item__button {
    color: white;
    border-color: white; }
    .similar-item__button:hover {
      cursor: pointer; }

.socials-widget {
  width: 72px;
  height: 72px;
  background-color: #008AFF;
  border-radius: 50%;
  position: fixed;
  bottom: 3%;
  right: 3%; }
  .socials-widget__circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap; }
    .socials-widget__circle svg {
      width: 50px;
      height: 50px; }
    .socials-widget__circle div {
      cursor: pointer;
      animation: slide 10s infinite linear;
      animation-direction: alternate; }
  .socials-widget__block {
    display: none; }
    .socials-widget__block::after {
      content: '';
      width: 15px;
      height: 15px;
      background: white;
      transform: rotate(45deg);
      position: absolute;
      bottom: -8px;
      right: 30px; }
    .socials-widget__block.is-open {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 120%;
      padding: 20px;
      background: white;
      border-radius: 25px;
      width: 200px;
      align-items: baseline;
      right: 0;
      box-shadow: 5px 5px 5px grey; }
      .socials-widget__block.is-open a {
        color: black;
        display: flex;
        align-items: center; }
        .socials-widget__block.is-open a svg {
          width: 50px; }

@keyframes slide {
  0% {
    margin-left: 0; }
  10% {
    margin-left: -50px; }
  20% {
    margin-left: -50px; }
  30% {
    margin-left: -100px; }
  40% {
    margin-left: -100px; }
  50% {
    margin-left: -150px; }
  60% {
    margin-left: -150px; }
  70% {
    margin-left: -200px; }
  80% {
    margin-left: -200px; }
  90% {
    margin-left: -250px; }
  100% {
    margin-left: -250px; } }

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 15px; }
  .slick-dots .slick-active button {
    border: 4px solid #82C5FF; }
  .slick-dots button {
    margin: 5px;
    color: #82C5FF;
    width: 5px;
    height: 5px;
    background: #82C5FF;
    border-radius: 50%;
    font-size: 0;
    cursor: pointer; }
