.motion-text {
	$this: &;
	position: relative;

	&__text {
		font-family: $font-family-accent;
		font-size: 30px;
		line-height: 1.2em;
		text-transform: uppercase;

		@include media(xs) {
			font-size: 40px;
		}

		@include media(md) {
			font-size: 50px;
		}

		@include media(xl) {
			font-size: 50px;
		}
	}

	&__image {
		display: none;
	}

	&__word {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		user-select: none;

		@include media(md) {
			justify-content: flex-start;
		}

		&:not(:last-child) {
			margin-right: 10px;

			@include media(md) {
				margin-right: rem(30);
			}
		}

		&.is-active {
			#{$this}__text {
				cursor: default;
				text-decoration: underline;

				&:hover {
					color: $color-main;
					// -webkit-text-stroke: 2px $color-black;
					text-decoration: none !important; // sass-lint:disable-line no-important
				}
			}
		}
	}

	&__canvas {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		pointer-events: none;
		will-change: transform;
		transform: translateZ(0);
	}
}
