.footer {
	.logo {
		width: auto;
	}

	&__copyright {
		font-family: Play;
		font-size: 13px;
		color: #000000;
		order: -1;
		width: 100%;
		text-align: center;
		margin-bottom: 40px;

		&--bottom {
			font-family: Roboto;
			font-size: 13px;
			line-height: 15px;
			color: #FFFFFF;
			opacity: 0.7;
			position: absolute;
			bottom: 40px;
			left: 0;
			right: 0;
		}

		@include media ($screen-def) {
			order: 0;
			width: auto;
			margin-bottom: 0;
		}
	}
}
