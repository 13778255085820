.section {
	padding-bottom: 50px;


	@include media(md) {
		padding-bottom: 70px;
	}

	@include media(xl) {
		padding-bottom: 100px;
	}

	&--video-block {
		height: 100vh;
		position: absolute;
		z-index: -1;
		left: 0;
		right: 0;
		padding: 0;
		margin: 0;

		@include media(md) {
			padding-bottom: 0;
		}

		@include media(xl) {
			padding-bottom: 0;
		}
	}
}
