.animate-block {
	position: relative;
	overflow: hidden;

	&__image {
		min-height: 200px;
		background-color: $color-gray-6;

		img {
			width: 100% !important; // sass-lint:disable-line no-important
		}
	}

	&__text {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
}
