.header {
	$this: &;
	padding-bottom: 30px;
	font-family: $font-family-play;

	@include media($screen-lg) {
		padding-top: 20px;
		padding-bottom: 50px;
	}

	@include media(md) {
		padding-bottom: 70px;
	}

	@include media(xl) {
		padding-bottom: 100px;
	}

	&__text {
		display: inline-block;
		font-size: 10px;
		color: $color-white;
		max-width: 120px;
		margin-left: 15px;

		@include media($screen-lg) {
			font-size: 12px;
		}
	}

	&__list {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		padding-top: 10px;
		padding-left: 40px;
		padding-bottom: 0;
		width: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right;
		background-color: $color-white;
		height: 300px;

		@include media($screen-lg) {
			padding-bottom: 100px;
			height: 350px;
		}


		&.is-last {
			left: auto;
			right: 0;
		}
	}

	&__link {
		display: block;
		text-decoration: none;
		transition: color 0.3s;
		color: $color-white;
		line-height: 1.4em;
		padding: 2px 0;
		cursor: pointer;
		font-size: 11px;

		@include media($screen-lg) {
			font-size: 14px;
		}

		&--arr {
			&::after {
				content: '▼';
				display: inline-block;
				vertical-align: bottom;
				font-size: .7rem;
			}
		}

		&--main {
			padding: 0 0 10px;
			border-bottom: 2px solid transparent;
			transition: color 0.3s;
			font-size: 14px;
			font-weight: normal;

			@include media($screen-lg) {
				font-size: 16px;
			}

			span {
				font-weight: bold;
			}
		}

		&--sub {
			font-size: 14px;
			line-height: 120%;
			padding: 6px 20px;
			color: black;
		}

		&--info {
			display: flex;
			position: relative;
			width: 60px;
			height: 60px;
			transition: 0.3s;
			border: 1px solid rgba(255, 255, 255, 0.2);

			&:hover,
			&:focus {
				background-color: #006ed9;
			}

			span {
				&::before {
					content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16L3.82931 14.8242C2.65058 14.1012 1.67256 13.0816 1 11.87L0 16Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 7.99995C0 3.58904 3.5888 0.000389099 8 0.000389099C12.4112 0.000389099 16 3.58904 16 7.99995C16 12.411 12.4112 15.9996 8 15.9996C6.47296 15.9996 5.04448 15.5695 3.82931 14.8242C2.65058 14.1012 1.67256 13.0816 1 11.87C0.362997 10.7225 0 9.40281 0 7.99995ZM8 15.0002C11.866 15.0002 15 11.8662 15 8.00019C15 4.1342 11.866 1.00019 8 1.00019C4.13401 1.00019 1 4.1342 1 8.00019C1 11.8662 4.13401 15.0002 8 15.0002Z' fill='white'/%3E%3Cpath d='M7.99998 6.30343C7.59833 6.30343 7.27271 6.62905 7.27271 7.0307V11.8784C7.27271 12.28 7.59833 12.6056 7.99998 12.6056C8.40163 12.6056 8.72725 12.28 8.72725 11.8784V7.0307C8.72725 6.6291 8.40163 6.30343 7.99998 6.30343Z' fill='white'/%3E%3Cpath d='M7.99998 3.39449C7.80846 3.39449 7.62126 3.47207 7.48555 3.60734C7.35028 3.74261 7.27271 3.93025 7.27271 4.12177C7.27271 4.31328 7.35028 4.50043 7.48555 4.63619C7.62083 4.77146 7.80846 4.84904 7.99998 4.84904C8.19149 4.84904 8.37864 4.77146 8.51435 4.63619C8.64963 4.50043 8.72725 4.31328 8.72725 4.12177C8.72725 3.93025 8.64963 3.74261 8.51435 3.60734C8.37864 3.47202 8.19149 3.39449 7.99998 3.39449Z' fill='white'/%3E%3C/svg%3E%0A");
					display: inline-block;
					position: absolute;
					right: 100%;

					@include media($screen-def) {
						margin-right: 10px;
					}
				}
			}

			@include media($screen-def) {
				padding-right: 20px;
				width: auto;
				height: auto;
				border: none;

				&::after {
					content: '';
					margin-left: 20px;
					width: 0;
					height: 12px;
					border: 1px solid rgba(255, 255, 255, 0.2);
				}

				&:hover,
				&:focus {
					background-color: transparent;
				}
			}
		}

		&--phone {
			padding: 0 10px;
			background: rgba(255, 255, 255, 0.3);
			margin-left: 8px;
			line-height: 24px;
		}

		&[href]:hover,
		&.is-active {
			color: $color-main;
		}

		svg {
			width: auto;
			height: auto;
			margin: auto;
		}
	}

	&__nav-line {
		display: flex;
		justify-content: space-between;
		padding: 0 -10px;
		flex-wrap: wrap;
	}

	&__nav-item {
		z-index: 1;
		padding: 0 10px;
		color: $color-black;
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;

		&:hover {
			z-index: 2;

			#{$this}__list {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				align-content: flex-start;
			}
		}
	}

	&__item {
		padding: 2px 20px 2px 0;
		width: 25%;
		display: flex;
		align-items: center;

		&::before {
			content: '';
			display: inline-block;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: #82C5FF;
		}
	}

	&__phones {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba(255, 255, 255, 0.2);
		transition: 0.3s;
		width: 60px;
		height: 60px;

		&:hover,
		&:focus {
			background-color: #006ed9;
		}

		@include media($screen-def) {
			border: none;
			width: auto;
			height: auto;

			&::before {
				content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99988 14.528C8.30036 14.528 8.54395 14.2844 8.54395 13.9839C8.54395 13.6834 8.30036 13.4399 7.99988 13.4399C7.6994 13.4399 7.45581 13.6834 7.45581 13.9839C7.45581 14.2844 7.6994 14.528 7.99988 14.528Z' fill='white'/%3E%3Cpath d='M11.1384 0H4.86144C3.94958 0 3.20782 0.741831 3.20782 1.65362V14.3464C3.20782 15.2582 3.94966 16 4.86144 16H11.1385C12.0503 16 12.7921 15.2582 12.7921 14.3464V1.65362C12.792 0.741831 12.0503 0 11.1384 0ZM4.27449 3.23577H11.7254V12.1173H4.27449V3.23577ZM4.86144 1.06667H11.1385C11.4621 1.06667 11.7254 1.32999 11.7254 1.65362V2.1691H4.27449V1.65362C4.27449 1.32999 4.53774 1.06667 4.86144 1.06667ZM11.1384 14.9333H4.86144C4.53782 14.9333 4.27449 14.67 4.27449 14.3464V13.1839H11.7254V14.3464C11.7254 14.67 11.4621 14.9333 11.1384 14.9333Z' fill='white'/%3E%3C/svg%3E%0A");
				display: inline-block;
				right: 100%;

				@include media($screen-def) {
					margin-right: 12px;

				}
			}

			&:hover,
			&:focus {
				background-color: transparent;
			}
		}

		&--mobile {
			display: flex;
			width: 100%;
			height: 100%;

			&.is-open {
				background-color: #006ed9;
			}
		}

		svg {
			width: auto;
			height: auto;
			margin: auto;
		}
	}
}
