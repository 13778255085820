.scroll-down {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	width: 20px;
	position: absolute;
	bottom: 20px;

	&::after {
		content: '';
		border: solid #919398;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 2px;
		transform: rotate(45deg);
		position: relative;
		top: -4px;
		animation: blinker 3s linear infinite;
	}

	&__line {
		width: 2px;
		height: rem(50);
		display: inline-block;
		position: relative;
		background-color: rgba($color-white, 0.5);
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			transform-origin: top center;
			animation: scroll-move 3s linear infinite;
			height: 50%;
			width: 100%;
			z-index: 2;
			background-color: $color-gray-1;
		}

		&:hover {
			color: $color-black;
		}
	}
}

@keyframes scroll-move {
	0% {
		transform: translateY(0%) scaleY(0);
	}

	10% {
		transform: translateY(0%) scaleY(0.5);
	}

	50% {
		transform: translateY(50%) scaleY(1);
	}

	90% {
		transform: translateY(200%) scaleY(0.5);
	}

	100% {
		transform: translateY(200%) scaleY(0);
	}
}

@keyframes blinker {
	from {
		border-color: #919398;
	}
	to {
		border-color: $color-gray-1;
	}
}
