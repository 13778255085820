.error {
	&__title {
		font-family: $font-family-accent;
		font-size: 100px;
		line-height: 1.2em;
		text-transform: uppercase;

		@include media(xl) {
			font-size: 150px;
		}
	}
}
