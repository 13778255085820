.scroll-up {
	$this: &;
	position: fixed;
	right: 0;
	bottom: 0;
	height: 100%;
	width: rem(40px);
	z-index: 100;
	visibility: hidden;

	@include media(xl) {
		width: rem(100px);
	}

	&__text {
		color: $color-black;
		text-transform: uppercase;
		font-size: 12px;
		padding-right: 0.625rem;
		letter-spacing: 1px;
		transition: color 0.3s ease;
	}

	&__arrow {
		display: block;
		vertical-align: top;
		position: relative;
		transition: width 0.3s ease;
		height: 2px;
		margin: 5px 5px 7px 0;
		width: rem(36px);
		background: $color-black;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 100%;
			margin-left: -7px;
			border: 2px solid;
			border-bottom-width: 0;
			border-left-width: 0;
			border-color: $color-black;
			width: 8px;
			height: 8px;
			transform: rotate(45deg);
			transform-origin: top right;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		cursor: pointer;
		position: absolute;
		left: 50%;
		bottom: 20px;
		transform: rotate(-90deg) translateY(50%);
		white-space: nowrap;
		transform-origin: 0 100%;
		user-select: none;
		opacity: 0;
		visibility: hidden;
		padding: 10px 0;
		transition: opacity 0.3s ease;

		&:hover {
			#{$this}__text {
				color: $color-main;
			}

			#{$this}__arrow {
				width: rem(70px);
			}
		}

		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}
}
