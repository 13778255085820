img {
	&[data-zzload-source-img] {
		opacity: 0;
		transition: opacity 0.3s;

		&[data-zzload-is-loaded] {
			opacity: 1;
			width: auto;
		}
	}
}
