.video-block {
	$this: &;
	position: relative;
	z-index: -1;

	&__video {
		max-width: none;
	}

	&__wrapper {
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(36, 43, 58, 0.5);
	}

	&__text {
		max-width: calc(100% - 20px);
		color: #fff;
		font-family: $font-family-accent;
		font-size: 50px;
		line-height: 1em;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;

		@include media(md) {
			font-size: 60px;
		}

		@include media(lg) {
			font-size: 80px;
		}
	}

	&--desktop {
		display: none;

		@include media(def) {
			display: block;
		}
	}

	&--mobile {
		display: block;

		@include media(def) {
			display: none;
		}
	}
}
