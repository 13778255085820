// sass-lint:disable no-important
// sass-lint:disable force-element-nesting

$control-height: 50px;
$control-font-size: 16px;
$control-font-size-small: 13px;

$control-color-text: $color-black;
$control-color-border: $color-gray-4;
$control-color-select: $color-main;
$control-color-error: $color-danger;

.control {
	$this: &;

	position: relative;
	display: block;

	&__inner {
		position: relative;
		display: block;

		&::before,
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 1px;
			background-color: $control-color-border;
			transition: transform $time-fast ease;
			transform-origin: left;
			will-change: transform;
		}

		&::before {
			z-index: 1;
		}

		&::after {
			z-index: 2;
			background-color: $control-color-select;
			transform: scaleX(0);
		}
	}

	&__label {
		display: block;
		color: $control-color-text;
		font-size: $control-font-size;
		font-weight: 300;
		cursor: pointer;
	}

	&__field {
		box-sizing: border-box;
		display: block;
		width: 100%;
		height: rem($control-height);
		padding: 0;
		background-color: $color-white;
		border: none;
		color: $control-color-text;
		font-size: rem($control-font-size);
		font-family: $font-family-main;
		line-height: rem($control-height);
		outline: none;
		transition: border-color $time-fast ease, box-shadow $time-fast ease, color $time-fast ease;
		will-change: border-color, box-shadow;

		&::placeholder {
			color: $control-color-border;
			font-size: rem($control-font-size);
			transition: color $time-fast ease;
			will-change: color;
		}

		&:-webkit-autofill ~ #{$this} {
			&__label {
				top: -2px;
				font-size: 12px;
			}
		}
	}

	&--input,
	&--textarea {
		#{$this} {
			&__label {
				position: absolute;
				top: calc((#{rem($control-height)} / 2) - (#{rem($control-font-size-small) / 2}));
				left: 0;
				z-index: 1;
				transition-property: top, font-size;
				transition-duration: $time-fast;
				transition-timing-function: ease;
				will-change: top, font-size;
			}
		}
	}

	// &--input {}

	&--textarea {
		#{$this} {
			&__field {
				min-height: 100px;
				padding: rem(20) 0;
				line-height: 1;
				resize: none;
			}
		}
	}

	&--select {
		#{$this} {
			&__field {
				// sass-lint:disable-all
				border-bottom: 1px solid $control-color-border;
				padding-right: 38px;
				background-image: url(/static/pic/icon-arrow-bottom-thin.svg);
				background-repeat: no-repeat;
				background-position: calc(100% - 14px) 50%;
				background-size: 1.2rem;
				font-size: rem($control-font-size);
				appearance: none !important;

				&::-ms-expand {
					display: none;
				}

				option {
					color: $control-color-text;
					font-size: 1.1em;

					&[disabled] {
						display: none;
					}
				}
				// sass-lint:enable-all
			}
		}

		select.s2s {
			height: rem($control-height);
			min-height: rem($control-height);
			max-height: rem($control-height) !important;
			overflow: hidden !important;
			background-color: transparent !important;
			color: transparent !important;
			border: none;
			border-bottom: 1px solid $control-color-border;
			width: 100%;

			&.select2-hidden-accessible {
				width: 0 !important;
				min-height: 0 !important;
				height: 0 !important;
				line-height: 0 !important;
				visibility: hidden !important;
				z-index: -1 !important;
				border-bottom: none !important;
			}

			&.has-error {
				~ .select2 {
					.s2s-container.s2s-container--default {
						border-color: $control-color-error !important;

						.select2-selection__arrow {
							b {
								border-top-color: $control-color-error !important;
							}
						}

						.select2-container--open & {
							border-color: $control-color-error !important;

							.select2-selection__arrow {
								b {
									border-bottom-color: $control-color-error !important;
								}
							}
						}
					}
				}
			}
		}
	}

	&--checkbox,
	&--radio {
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		max-width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		border: none;
		box-shadow: none;
		background-color: rgba($color-white, 0.01);
		cursor: default;

		#{$this} {
			&__field {
				@include visuallyhidden();

				// &:checked ~ {
				// 	#{$this} {
				// 		&__icon {}

				// 		&__text {}
				// 	}
				// }
			}

			&__icon {
				flex-shrink: 0;
				position: relative;
				display: block;
				width: 16px;
				height: 16px;
				background-color: $color-white;
				border: 1px solid $control-color-border;
				transition: border-color $time-fast ease, background-color $time-fast ease;

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
					display: block;
					margin: auto;
					transform-origin: center;
					transition: $time-fast ease;
				}

				& + #{$this}__text {
					padding-left: 10px;
				}
			}

			&__text {
				color: $control-color-text;
				font-size: $control-font-size-small;
				text-overflow: ellipsis;
				overflow: hidden;
				user-select: none;
				line-height: 1em;

				a {
					color: $control-color-select;
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}

	&--checkbox {
		#{$this} {
			&__field {
				&:checked ~ {
					#{$this}__icon {
						background-color: $control-color-select;
						border-color: $control-color-select;

						&::after {
							opacity: 1;
						}
					}
				}
			}

			&__icon {
				&::after {
					top: -6px;
					width: 8px;
					height: 4px;
					border-style: solid;
					border-width: 2px;
					border-color: transparent transparent $color-white $color-white;
					transform: rotate(-45deg) skew(-10deg, -5deg);
					opacity: 0;
				}
			}

			// &__text {}
		}
	}

	&--radio {
		#{$this} {
			&__field {
				&:checked ~ {
					#{$this}__icon {
						border-color: $control-color-select;

						&::after {
							transform: scale(1);
							opacity: 1;
						}
					}
				}
			}

			&__icon {
				border-radius: 50%;

				&::after {
					width: 8px;
					height: 8px;
					border-radius: inherit;
					background-color: $control-color-select;
					transform: scale(.5);
					opacity: 0;
				}
			}
		}
	}

	&--file {
		#{$this} {
			&__item {
				transition: border-color 0.3s;
				color: $control-color-text;
				font-size: rem($control-font-size);
				line-height: rem($control-height);
				width: 100%;
				height: rem($control-height);
				display: block;
				max-width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				cursor: default;
				user-select: none;

				&.is-placeholder {
					color: $control-color-border;
				}
			}

			&__field {
				position: absolute;
				width: 0;
				height: 0;
				line-height: 0;
				visibility: hidden;
				z-index: -1;
			}
		}
	}

	&.in-focus,
	&.has-value {
		&#{$this}--input,
		&#{$this}--textarea {
			#{$this} {
				&__inner {
					&::after {
						transform: scaleX(1);
					}
				}

				&__label {
					top: 0;
					font-size: rem($control-font-size-small);
					line-height: 1em;
				}
			}
		}

		&#{$this}--input {
			#{$this} {
				&__field {
					padding-top: rem($control-font-size-small);
				}
			}
		}

		&#{$this}--textarea {
			#{$this} {
				&__field {
					padding-top: rem($control-font-size-small + 10px);
				}
			}
		}
	}

	// Вынесен отдельным блоки из-за бага в Mozzila, по скольку при склейке стилей Mozzila перестает воспринимать данное правило
	&:-webkit-autofill {
		&#{$this}--input,
		&#{$this}--textarea {
			#{$this} {
				&__inner {
					&::after {
						transform: scaleX(1);
					}
				}

				&__label {
					top: -2px;
					font-size: 12px;
				}
			}
		}
	}
}

label {
	&.has-error {
		position: absolute;
		top: 100%;
		left: 0;
		font-size: rem(11);
		background: $color-white;
		color: $control-color-error;
		padding: 4px 8px;
		border: 1px solid;
		margin-top: 5px;
		z-index: 9;

		&::after,
		&::before {
			bottom: 100%;
			left: rem(10);
			border: solid transparent;
			content: '';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&::after {
			border-color: rgba($color-white, 0);
			border-bottom-color: $color-white;
			border-width: 4px;
			margin-left: -4px;
		}

		&::before {
			border-color: rgba($control-color-error, 0);
			border-bottom-color: $control-color-error;
			border-width: 5px;
			margin-left: -5px;
		}
	}
}

.range-input {
	display: block;
	position: relative;

	&__value {
		width: 100%;
		height: rem($control-height);
		padding: 0 rem(8) 0 rem(32);
		border: 1px solid $color-gray-3;
		font-size: rem($control-font-size);
	}

	&__label {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: rem(8);
		font-size: rem($control-font-size);
		color: $color-gray-4;
	}
}
