.slick-dots {
	display: flex;
	justify-content: center;
	margin: 15px;

	.slick-active {
		button {
			border: 4px solid #82C5FF;
		}
	}

	button {
		margin: 5px;
		color: #82C5FF;
		width: 5px;
		height: 5px;
		background: #82C5FF;
		border-radius: 50%;
		font-size: 0;
		cursor: pointer;
	}
}
